import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {withAlert} from "react-alert";
import EditorJS from '@editorjs/editorjs';
import {getEditorJSTools} from "./editor-constants";

import {getUser} from "../../auth/Auth";
import {handleDeleteAllCheckBox, handleDeleteCheckBox} from "./Common";
import {deleteRequest, uploadFile} from "../../routes/Routes";
import {loadPromotions} from "../../common/Common";

let editor = null;

class Promotion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            action: "",
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            promotions: [],
            deletePromotions: [],
            selectedPromotion: null,
            headerImage: null,
        };
        this.headerImageInput = React.createRef();
    }

    componentDidMount() {
        getUser(this.props.history).then(data => {
            this.setState({user: data});
        }).catch(() => null);
        loadPromotions().then(data => {
            this.setState({promotions: data});
        }).catch(() => null);
    }

    render() {
        let {
            user,
            loading,
            action,
            error,
            errorMessage,
            success,
            successMessage,
            promotions,
            deletePromotions,
            selectedPromotion,
            headerImage,
        } = this.state;
        if (user && (action === 'Add New Promotion' || action === 'Edit Promotion') && !editor) {
            editor = new EditorJS({
                holder: 'editor',
                tools: getEditorJSTools(user, '/api/promotions/upload/image'),
                placeholder: 'Promotion content',
                data: selectedPromotion ? JSON.parse(selectedPromotion.content) : null,
            });
        }
        return (
            <div>
                {action === 'Add New Promotion' || action === 'Edit Promotion' ?
                    <form onSubmit={this.formSubmit} className="admin-form">
                        <h1 className="heading admin-heading">{action}</h1>
                        <div className="form-row" style={{marginBottom: '2%'}}>
                            <div className="form-group col-md-4">
                                <label htmlFor="name" className="form-label">Promotion Name</label>
                                {selectedPromotion ?
                                    <input className="form-control" type="text" placeholder='Promotion Name' id="name"
                                           required
                                           value={selectedPromotion.name}
                                           onChange={(event) => this.handleChange(event, 'name')}/>
                                    :
                                    <input className="form-control" type="text" placeholder='Promotion Name' id="name"
                                           required/>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="tier" className="form-label">Promotion Type</label>
                                {selectedPromotion ?
                                    <select className="form-control" id="tier" value={selectedPromotion.tier} onChange={(event) => this.handleChange(event, 'tier')}>
                                        <option value="general">General</option>
                                        <option value="silver">Silver</option>
                                        <option value="gold">Gold</option>
                                    </select>
                                    :
                                    <select className="form-control" id="tier" onChange={(event) => this.handleChange(event, 'tier')}>
                                        <option value="" disabled>Select Promotion Type</option>
                                        <option value="general">General</option>
                                        <option value="silver">Silver</option>
                                        <option value="gold">Gold</option>
                                    </select>
                                }
                                <small id="tier" className="form-text text-muted spc-portal-txt" style={{fontSize: '14px'}}>
                                    Click to select
                                </small>
                            </div>


                            <div className="form-group col-md-4">
                                <label htmlFor="promotions" className="form-label">Promotion List</label>
                                {selectedPromotion ?
                                    <input className="form-control" type="text" placeholder='Promotion List' id="promotions"
                                           required
                                           value={selectedPromotion.promotions}
                                           onChange={(event) => this.handleChange(event, 'promotions')}/>
                                    :
                                    <input className="form-control" type="text" placeholder='Promotion List' id="promotions"
                                           required/>
                                }
                                <small id="promotions" className="form-text text-muted spc-portal-txt" style={{fontSize: '14px'}}>
                                    Separate using comma "," i.e: Car Wash, Interior Polish, Exterior Polish
                                </small>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="slug" className="form-label">Promotion Slug</label>
                                {selectedPromotion ?
                                    <input className="form-control" type="text" placeholder='Promotion Slug' id="slug"
                                           required
                                           value={selectedPromotion.slug}
                                           onChange={(event) => this.handleChange(event, 'slug')}/>
                                    :
                                    <input className="form-control" type="text" placeholder='Promotion Slug' id="slug"
                                           required/>
                                }
                                <small id="slug" className="form-text text-muted spc-portal-txt" style={{fontSize: '14px'}}>
                                    Promotion URL
                                </small>
                            </div>
                        </div>
                        <div className="custom-file" style={{marginBottom: '2%'}}>
                            {headerImage ?
                                <img src={URL.createObjectURL(headerImage)}
                                     style={{marginBottom: '5%', width: 'auto'}}/>
                                : selectedPromotion ?
                                    <img src={selectedPromotion.header_image_url}
                                         style={{marginBottom: '5%', width: 'auto'}}/>
                                    : null
                            }
                            <input type="file" className="custom-file-input" id="promotionHeaderImage"
                                   accept="image/*"
                                   ref={this.headerImageInput}
                                   onChange={(event) => this.handleChange(this.headerImageInput, 'headerImage')}/>
                            <label className="custom-file-label" htmlFor="promotionHeaderImage">
                                <small id="promotionHeaderImage" className="form-text text-muted"
                                       style={{marginBottom: '2%', textAlign: 'center'}}>
                                    Add Promotion Header Image
                                </small>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"
                                     alt="Add Icon">
                                    <path fill="none" d="M0 0h24v24H0z"/>
                                    <path
                                        d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993v9.349A5.99 5.99 0 0 0 20 13V5H4l.001 14 9.292-9.293a.999.999 0 0 1 1.32-.084l.093.085 3.546 3.55a6.003 6.003 0 0 0-3.91 7.743L2.992 21A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/>
                                </svg>
                                <div className="image-uploader-label">
                                    Drag & drop or select from your files
                                </div>
                            </label>
                        </div>
                        <div className="form-row" style={{marginBottom: '2%'}}>
                            <div className="form-group col-md-3">
                                <label htmlFor="header_paragraph">Promotion Subheading</label>
                                {selectedPromotion ?
                                    <input className="form-control" placeholder='Subheading'
                                              id="header_paragraph"
                                              required
                                              value={selectedPromotion.header_paragraph}
                                              onChange={(event) => this.handleChange(event, 'header_paragraph')}/>
                                    :
                                    <input className="form-control" placeholder='Subheading'
                                              id="header_paragraph"
                                              required/>
                                }
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="price" className="form-label">Promotion price</label>
                                {selectedPromotion ?
                                    <input className="form-control" type="text" placeholder='Promotion Price' id="price"
                                           value={selectedPromotion.price}
                                           onChange={(event) => this.handleChange(event, 'price')}/>
                                    :
                                    <input className="form-control" type="text" placeholder='Promotion Price' id="price"
                                    />
                                }
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="editor" className="form-label">Promotion Editor</label>
                                <div className="form-group text-editor">
                                    <div id="editor"/>
                                </div>
                            </div>
                        </div>
                        {success &&
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>}
                        {error &&
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>}
                        <div className="form-row">
                            <button className="btn white-button form-button col mr-3" style={{marginRight: '5%'}} id=""
                                    onClick={this.actionClick}>
                                Cancel
                            </button>
                            <button className="btn red-button form-button col">
                                {loading ?
                                    <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                          aria-hidden="true"/>
                                    : null
                                }
                                {action === 'Add New Promotion' ? 'Add' : 'Save'}
                            </button>
                        </div>
                    </form>
                    : [
                        <h1 className="heading admin-heading" key="heading">Promotions</h1>,
                        <div key="promotion-delete" className="action-container">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'promotionSelectAll'}
                                       checked={promotions.length === deletePromotions.length}
                                       onChange={() => this.handleDeleteAllCheckBox()}/>
                                <label className="custom-control-label" htmlFor={'promotionSelectAll'}>Select All</label>
                            </div>
                            <div className="action-button-container">
                                <button className="btn gold-border-button" onClick={this.delete}
                                        disabled={deletePromotions.length === 0}>
                                    Delete
                                </button>
                                <button className="btn gold-button" id="Add New Promotion"
                                        onClick={this.actionClick}>
                                    Add
                                </button>
                            </div>
                        </div>,
                        <table className="w-100 table-hover admin-table" key="promotion-table">
                            <thead>
                            <tr>
                                <th/>
                                <th>No</th>
                                <th>Name</th>
                                <th>Promotion</th>
                                <th>Price</th>
                                <th>Header Image</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.tableBody()}
                            </tbody>
                        </table>,
                    ]}
            </div>
        );
    };

    actionClick = (event, promotion = null) => {
        if (!promotion) event.preventDefault();
        editor = null;
        this.setState({
            action: promotion ? event : event.target.id,
            selectedPromotion: promotion,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            headerImage: null,
        });
    };

    formSubmit = async (event) => {
        let {user, promotions, action, selectedPromotion} = this.state,
            endpoint,
            content = null;
        event.preventDefault();
        if (editor) {
            await editor.save().then((outputData) => {
                content = outputData;
            }).catch((error) => {
                console.log('Saving failed: ', error)
            });
        }
        const data = new FormData();
        if (action === 'Add New Promotion') {
            data.append('name', event.target.name.value);
            data.append('tier', event.target.tier.value);
            data.append('promotions', event.target.promotions.value);
            data.append('price', event.target.price.value);
            data.append('slug', event.target.slug.value);
            data.append('content', JSON.stringify(content));
            data.append('header_paragraph', event.target.header_paragraph.value);
            if (this.headerImageInput.current.files.length > 0) {
                data.append('header_image', this.headerImageInput.current.files[0]);
            }
            endpoint = '/api/promotions';
        } else {
            data.append('name', selectedPromotion.name);
            data.append('tier', selectedPromotion.tier);
            data.append('promotions', selectedPromotion.promotion);
            data.append('price', event.target.price.value);
            data.append('slug', selectedPromotion.slug);
            data.append('content', JSON.stringify(content));
            data.append('header_paragraph', selectedPromotion.header_paragraph);
            if (selectedPromotion.headerImage) {
                data.append('header_image', selectedPromotion.headerImage);
            }
            endpoint = '/api/promotions/update/' + selectedPromotion.id;
        }
        uploadFile(data, endpoint, user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.setState({
                        error: true,
                        errorMessage: response.data.message,
                        success: false,
                        successMessage: null,
                    });
                } else {
                    if (action === 'Add New Promotion') {
                        promotions.push(response.data.promotion);
                    } else {
                        promotions[promotions.indexOf(selectedPromotion)] = response.data.promotion;
                    }
                    this.setState({
                        error: false,
                        errorMessage: null,
                        success: true,
                        successMessage: response.data.message,
                        promotions: promotions,
                    });
                }
            } else {
                this.setState({error: true, errorMessage: 'An error occurred!'});
            }
            this.setState({loading: false});
        });
    }

    tableBody = () => {
        let body = [],
            {promotions, deletePromotions} = this.state;
        if (promotions) {
            promotions.forEach((promotion, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                       id={'promotionDelete' + promotion.id}
                                       checked={deletePromotions.includes(promotion.id)}
                                       onChange={(event) => this.handleDeleteCheckBox(promotion.id)}/>
                                <label className="custom-control-label" htmlFor={'promotionDelete' + promotion.id}/>
                            </div>
                        </td>
                        <td>{index + 1}</td>
                        <td>{promotion.name}</td>
                        <td>
                            <div style={{
                                width: 200,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}>{promotion.promotions}</div>
                        </td>
                        <td>Rs. {promotion.price}</td>
                        <td className="image-column">
                            {promotion.header_image_url ?
                                <img src={promotion.header_image_url}
                                     style={{width: '65px', height: '65px'}}/>
                                : null
                            }
                        </td>
                        <td className="action-column">
                            <span onClick={() => this.actionClick('Edit Promotion', promotion)}>Edit</span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    handleDeleteCheckBox = async (id) => {
        let {deletePromotions} = this.state;
        this.setState({deletePromotions: handleDeleteCheckBox(id, deletePromotions)});
    };

    handleDeleteAllCheckBox() {
        let {deletePromotions, promotions} = this.state;
        this.setState({deletePromotions: handleDeleteAllCheckBox(deletePromotions, promotions)});
    }

    delete = () => {
        let {user, promotions, deletePromotions} = this.state;
        deletePromotions.forEach((promotion, index) => {
            deleteRequest('/api/promotions/' + promotion, user.api_token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        this.props.alert.error(response.data.message);
                    } else {
                        if (deletePromotions.length === index + 1) {
                            this.setState({
                                promotions: promotions.filter(function (promotion) {
                                    return !deletePromotions.includes(promotion.id);
                                }),
                                deletePromotions: []
                            });
                            this.props.alert.success("Successfully deleted Promotions");
                        }
                    }
                } else {
                    this.props.alert.error("An error occurred!");
                }
            });
        });
    }

    handleChange(event, key) {
        let {selectedPromotion, headerImage} = this.state;
        if (selectedPromotion) {
            if (key === 'headerImage') {
                headerImage = event.current.files[0];
                selectedPromotion[key] = headerImage;
            } else {
                selectedPromotion[key] = event.target.value;
            }
            this.setState({
                selectedPromotion: selectedPromotion,
                headerImage: headerImage,
            });
        } else {
            if (key === 'headerImage') {
                headerImage = event.current.files[0];
            }
            this.setState({
                headerImage: headerImage,
            });
        }
    }
}

export default withAlert()(withRouter(Promotion));
