import React from 'react';
import {Carousel} from "react-responsive-carousel";
import '../assets/css/Partner.css';

import towking from '../assets/images/towking.PNG';
import valvoline from '../assets/images/valvoline_1.webp';
import motul from '../assets/images/motul.webp';
import petronas from '../assets/images/veedol_1.webp';
import fourx from '../assets/images/4x.webp';
import liqui from '../assets/images/liquimoly.webp';
import hendlex from '../assets/images/Hendlex.png';

function Partners() {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
        },
    };
    return ([
        <div className="partner-container pt-5 pb-5 desktop-partner-container">
            <div className="d-flex bd-highlight w-100 container">
            {/* <Carousel showThumbs={false} showArrows={false} autoPlay={true} infiniteLoop={true}
                      showStatus={false}> */}
                        {/* <div className="logo-pair-container d-flex  bd-highlight w-100 container"> */}
                <div className="p-2 flex-fill bd-highlight">
                    <img src={liqui} alt="Liqui Logo"/>
                </div>
                <div className="p-2 flex-fill bd-highlight">
                    <img src={fourx} alt="4x Logo"/>
                </div>
                <div className="p-2 flex-fill bd-highlight">
                    <img src={petronas} alt="Petronas Logo"/>
                </div>
                <div className="p-2 flex-fill bd-highlight">
                    <img src={motul} alt="Motul Logo"/>
                </div>
                <div className="p-2 flex-fill bd-highlight">
                    <img src={valvoline} alt="Valvoline Logo"/>
                </div>
                {/* </div> */}
                {/* </Carousel> */}
            </div>
        </div>,
        <div className="partner-container mobile-partner-container">
            <Carousel showThumbs={false} showArrows={false} autoPlay={true} infiniteLoop={true}
                      showStatus={false}>
                <div className="logo-pair-container">
                    <div className="carousel-detail-container">
                        <img src={liqui} alt="Liqui Logo"/>
                    </div>
                    <div className="carousel-detail-container">
                        <img src={fourx} alt="4x Logo"/>
                    </div>
                </div>
                <div className="logo-pair-container">
                    <div className="carousel-detail-container">
                        <img src={petronas} alt="Petronas Logo"/>
                    </div>
                    <div className="carousel-detail-container">
                        <img src={motul} alt="Motul Logo"/>
                    </div>
                </div>
                <div className="logo-pair-container">
                    <div className="carousel-detail-container">
                        <img src={valvoline} alt="Valvoline Logo"/>
                    </div>
                </div>
              
            </Carousel>
        </div>
    ])
}

export default Partners;
