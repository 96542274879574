import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {withAlert} from "react-alert";
import {getUser} from "../../auth/Auth";
import {handleDeleteAllCheckBox, handleDeleteCheckBox} from "./Common";
import {deleteRequest, uploadFile} from "../../routes/Routes";
import {loadTestimonials} from "../../common/Common";

class Testimonials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            action: "",
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            testimonials: [],
            deleteTestimonials: [],
            selectedTestimonial: null,
        };
        this.profileImageInput = React.createRef();
    }

    componentDidMount() {
        getUser(this.props.history).then(data => {
            this.setState({user: data});
        }).catch(() => null);
        loadTestimonials().then(data => {
            this.setState({testimonials: data});
        }).catch(() => null);
    }

    render() {
        let {
            loading,
            action,
            error,
            errorMessage,
            success,
            successMessage,
            testimonials,
            deleteTestimonials,
            selectedTestimonial,
        } = this.state;
        return (
            <div>
                {action === 'Add New Testimonial' || action === 'Edit Testimonial' ?
                    <form onSubmit={this.formSubmit} className="admin-form">
                        <h1 className="heading admin-heading">{action}</h1>
                        <div className="form-group">
                            <label htmlFor="name" className="form-label">Client Name</label>
                            {selectedTestimonial ?
                                <input className="form-control" type="text" placeholder='Client Name' id="name"
                                       required
                                       value={selectedTestimonial.name}
                                       onChange={(event) => this.handleChange(event, 'name')}/>
                                :
                                <input className="form-control" type="text" placeholder='Client Name' id="name"
                                       required/>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="designation" className="form-label">Designation</label>
                            {selectedTestimonial ?
                                <input className="form-control" type="text" placeholder='Designation' id="designation"
                                       required
                                       value={selectedTestimonial.designation}
                                       onChange={(event) => this.handleChange(event, 'designation')}/>
                                :
                                <input className="form-control" type="text" placeholder='Designation' id="designation"
                                       required/>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="message" className="form-label">Message</label>
                            {selectedTestimonial ?
                                <textarea className="form-control" style={{height: 200}} placeholder='Message'
                                          id="message"
                                          required
                                          value={selectedTestimonial.message}
                                          onChange={(event) => this.handleChange(event, 'message')}/>
                                :
                                <textarea className="form-control" style={{height: 200}} placeholder='Message'
                                          id="message"
                                          required/>
                            }
                        </div>
                        {success &&
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>}
                        {error &&
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>}
                        <div className="form-row">
                        <button className="btn white-button form-button col mr-3" style={{marginRight:'5%'}} id=""
                                onClick={this.actionClick}>
                            Cancel
                        </button>
                        <button className="btn red-button form-button col">
                            {loading ?
                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                      aria-hidden="true"/>
                                : null
                            }
                            {action === 'Add New Testimonial' ? 'Add' : 'Save'}
                        </button>
                       
                        </div>
                    </form>
                    : [
                        <h1 className="heading admin-heading" key="heading">Testimonials</h1>,
                        <div key="testimonial-delete" className="action-container">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'testimonialSelectAll'}
                                       checked={testimonials.length === deleteTestimonials.length}
                                       onChange={() => this.handleDeleteAllCheckBox()}/>
                                <label className="custom-control-label" htmlFor={'testimonialSelectAll'}>Select
                                    All</label>
                            </div>
                            <div className="action-button-container">
                                <button className="btn gold-border-button" onClick={this.delete}
                                        disabled={deleteTestimonials.length === 0}>
                                    Delete
                                </button>
                                <button className="btn gold-button" id="Add New Testimonial"
                                        onClick={this.actionClick}>
                                    Add
                                </button>
                            </div>
                        </div>,
                        <table className="w-100 admin-table" key="testimonial-table">
                            <thead>
                            <tr>
                                <th/>
                                <th>No</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Message</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.tableBody()}
                            </tbody>
                        </table>,
                    ]}
            </div>
        );
    };

    actionClick = (event, testimonial = null) => {
        if (!testimonial) event.preventDefault();
        this.setState({
            action: testimonial ? event : event.target.id,
            selectedTestimonial: testimonial,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
        });
    };

    formSubmit = (event) => {
        let {user, testimonials, action, selectedTestimonial} = this.state,
            endpoint;
        event.preventDefault();
        const data = new FormData();
        if (action === 'Add New Testimonial') {
            data.append('name', event.target.name.value);
            data.append('designation', event.target.designation.value);
            data.append('message', event.target.message.value);
            endpoint = '/api/testimonials';
        } else {
            data.append('name', selectedTestimonial.name);
            data.append('designation', selectedTestimonial.designation);
            data.append('message', selectedTestimonial.message);
            endpoint = '/api/testimonials/update/' + selectedTestimonial.id;
        }
        uploadFile(data, endpoint, user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.setState({
                        error: true,
                        errorMessage: response.data.message,
                        success: false,
                        successMessage: null,
                    });
                } else {
                    if (action === 'Add New Testimonial') {
                        testimonials.push(response.data.testimonial);
                    } else {
                        testimonials[testimonials.indexOf(selectedTestimonial)] = response.data.testimonial;
                    }
                    this.setState({
                        error: false,
                        errorMessage: null,
                        success: true,
                        successMessage: response.data.message,
                        testimonials: testimonials,
                    });
                }
            } else {
                this.setState({error: true, errorMessage: 'An error occurred!'});
            }
            this.setState({loading: false});
        });
    }

    tableBody = () => {
        let body = [],
            {testimonials, deleteTestimonials} = this.state;
        if (testimonials) {
            testimonials.forEach((testimonial, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                       id={'testimonialDelete' + testimonial.id}
                                       checked={deleteTestimonials.includes(testimonial.id)}
                                       onChange={(event) => this.handleDeleteCheckBox(testimonial.id)}/>
                                <label className="custom-control-label" htmlFor={'testimonialDelete' + testimonial.id}/>
                            </div>
                        </td>
                        <td>{index + 1}</td>
                        <td>{testimonial.name}</td>
                        <td>{testimonial.designation}</td>
                        <td>
                            <div style={{
                                width: 200,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}>{testimonial.message}</div>
                        </td>
                        <td className="action-column">
                            <span onClick={() => this.actionClick('Edit Testimonial', testimonial)}>Edit</span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    handleDeleteCheckBox = async (id) => {
        let {deleteTestimonials} = this.state;
        this.setState({deleteTestimonials: handleDeleteCheckBox(id, deleteTestimonials)});
    };

    handleDeleteAllCheckBox() {
        let {deleteTestimonials, testimonials} = this.state;
        this.setState({deleteTestimonials: handleDeleteAllCheckBox(deleteTestimonials, testimonials)});
    }

    delete = () => {
        let {user, testimonials, deleteTestimonials} = this.state;
        deleteTestimonials.forEach((testimonial, index) => {
            deleteRequest('/api/testimonials/' + testimonial, user.api_token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        this.props.alert.error(response.data.message);
                    } else {
                        if (deleteTestimonials.length === index + 1) {
                            this.setState({
                                testimonials: testimonials.filter(function (testimonial) {
                                    return !deleteTestimonials.includes(testimonial.id);
                                }),
                                deleteTestimonials: []
                            });
                            this.props.alert.success("Successfully deleted Testimonials");
                        }
                    }
                } else {
                    this.props.alert.error("An error occurred!");
                }
            });
        });
    }

    handleChange(event, key) {
        let {selectedTestimonial} = this.state;
        selectedTestimonial[key] = event.target.value;
        this.setState({selectedTestimonial: selectedTestimonial});
    }
}

export default withAlert()(withRouter(Testimonials));
