import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {withAlert} from "react-alert";
import EditorJS from '@editorjs/editorjs';
import {getEditorJSTools} from "./editor-constants";

import {getUser} from "../../auth/Auth";
import {handleDeleteAllCheckBox, handleDeleteCheckBox} from "./Common";
import {deleteRequest, uploadFile} from "../../routes/Routes";
import {loadBlogs} from "../../common/Common";

let editor = null;

class Blogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            action: "",
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            blogs: [],
            deleteBlogs: [],
            selectedBlog: null,
            headerImage: null,
            isLink: true,
            user: null,
        };
        this.headerImageInput = React.createRef();
    }

    componentDidMount() {
        getUser(this.props.history).then(data => {
            this.setState({user: data});
        }).catch(() => null);
        loadBlogs().then(data => {
            this.setState({blogs: data});
        }).catch(() => null);
    }

    render() {
        let {
            loading,
            action,
            error,
            errorMessage,
            success,
            successMessage,
            blogs,
            deleteBlogs,
            selectedBlog,
            headerImage,
            user,
            isLink,
        } = this.state;
        if (user && (action === 'Add New Blog' || action === 'Edit Blog') && !editor && ((selectedBlog && !selectedBlog.is_link) || !isLink)) {
            editor = new EditorJS({
                holder: 'editor',
                tools: getEditorJSTools(user, '/api/blogs/upload/image'),
                placeholder: 'Blog content',
                data: selectedBlog ? JSON.parse(selectedBlog.content) : null,
            });
        }
        return (
            <div>
                {action === 'Add New Blog' || action === 'Edit Blog' ?
                    <form onSubmit={this.formSubmit} className="admin-form">
                        <h1 className="heading admin-heading">{action}</h1>
                        <div className="form-group">
                            <label htmlFor="name" className="form-label">Blog Name</label>
                            {selectedBlog ?
                                <input className="form-control" type="text" placeholder='Blog Name' id="name"
                                       required
                                       value={selectedBlog.name}
                                       onChange={(event) => this.handleChange(event, 'name')}/>
                                :
                                <input className="form-control" type="text" placeholder='Blog Name' id="name"
                                       required/>
                            }
                        </div>
                        <div className="form-row" style={{marginBottom: '2%', marginTop: '2%'}}>
                            <div className="form-group col-md-6">
                                <div className="custom-file">
                                    {headerImage ?
                                        <img src={URL.createObjectURL(headerImage)}
                                             style={{marginBottom: '5%', width: 'auto'}}/>
                                        : selectedBlog ?
                                            <img src={selectedBlog.header_image_url}
                                                 style={{marginBottom: '5%', width: 'auto'}}/>
                                            : null
                                    }
                                    <input type="file" className="custom-file-input" id="blogHeaderImage"
                                           accept="image/*"
                                           ref={this.headerImageInput}
                                           onChange={(event) => this.handleChange(this.headerImageInput, 'headerImage')}/>
                                    <label className="custom-file-label" htmlFor="blogHeaderImage">
                                        <small id="blogHeaderImage" className="form-text text-muted"
                                               style={{marginBottom: '2%', textAlign: 'center'}}>
                                            Add Blog Header Image
                                        </small>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48"
                                             height="48"
                                             alt="Add Icon">
                                            <path fill="none" d="M0 0h24v24H0z"/>
                                            <path
                                                d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993v9.349A5.99 5.99 0 0 0 20 13V5H4l.001 14 9.292-9.293a.999.999 0 0 1 1.32-.084l.093.085 3.546 3.55a6.003 6.003 0 0 0-3.91 7.743L2.992 21A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/>
                                        </svg>
                                        <div className="image-uploader-label">
                                            Drag & drop or select from your files
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <div className="form-group radio-button-container">
                                    <div className="custom-control custom-radio form-check-inline">
                                        <input type="radio" id="isLink" name="is_link" value={true}
                                               className="custom-control-input"
                                               defaultChecked={selectedBlog ? selectedBlog.is_link : isLink}
                                               onChange={() => this.handleChange(true, 'is_link')}/>
                                        <label className="custom-control-label" htmlFor="isLink">Link</label>
                                    </div>
                                    <div className="custom-control custom-radio form-check-inline">
                                        <input type="radio" id="blogEditor" name="is_link" value={false}
                                               className="custom-control-input"
                                               defaultChecked={selectedBlog ? !selectedBlog.is_link : !isLink}
                                               onChange={() => this.handleChange(false, 'is_link')}/>
                                        <label className="custom-control-label" htmlFor="blogEditor">Blog Editor</label>
                                    </div>
                                </div>
                                {(selectedBlog && selectedBlog.is_link) || isLink ?
                                    <div className="form-group" id='linked-text-block'>
                                        <label htmlFor="link" className="form-label">Redirect Link</label>
                                        {selectedBlog ?
                                            <input className="form-control" type="text" placeholder='Redirect Link'
                                                   id="link"
                                                   name="link" required value={selectedBlog.link}
                                                   onChange={(event) => this.handleChange(event, 'link')}/>
                                            : <input className="form-control" type="text" placeholder='Redirect Link'
                                                     id="link"
                                                     name="link" required/>
                                        }
                                        {isLink &&
                                            <div className="form-group" id='additional-info-block'>
                                                <label htmlFor="additionalInfo" className="form-label">Additional Info</label>
                                                {selectedBlog ?
                                                    <textarea
                                                        className="form-control"
                                                        id="additionalInfo"
                                                        placeholder='Additional Info'
                                                        value={selectedBlog.additional_info}
                                                        onChange={(event) => this.handleChange(event, 'additional_info')}
                                                    />
                                                    :
                                                    <textarea
                                                        className="form-control"
                                                        id="additionalInfo"
                                                        placeholder='Additional Info'
                                                        onChange={(event) => this.handleChange(event, 'additional_info')}
                                                    />
                                                }
                                            </div>
                                        }
                                    </div>
                                    : [
                                        <label htmlFor="editor" id="editor-label" className="form-label"
                                               key="blogLabel">Blog Editor</label>,
                                        <div className="form-group text-editor" id='linked-text-block' key="editor">
                                            <div id="editor"/>
                                        </div>
                                    ]
                                }
                            </div>
                        </div>
                        {success &&
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>}
                        {error &&
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>}
                        <div className="form-row">
                            <button className="btn white-button form-button col mr-3" style={{marginRight: '5%'}} id=""
                                    onClick={this.actionClick}>
                                Cancel
                            </button>
                            <button className="btn red-button form-button col">
                                {loading ?
                                    <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                          aria-hidden="true"/>
                                    : null
                                }
                                {action === 'Add New Blog' ? 'Add' : 'Save'}
                            </button>
                        </div>
                    </form>
                    : [
                        <h1 className="heading admin-heading" key="heading">Blogs</h1>,
                        <div key="blog-delete" className="action-container">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'blogSelectAll'}
                                       checked={blogs.length === deleteBlogs.length}
                                       onChange={() => this.handleDeleteAllCheckBox()}/>
                                <label className="custom-control-label" htmlFor={'blogSelectAll'}>Select All</label>
                            </div>
                            <div className="action-button-container">
                                <button className="btn gold-border-button" onClick={this.delete}
                                        disabled={deleteBlogs.length === 0}>
                                    Delete
                                </button>
                                <button className="btn gold-button" id="Add New Blog"
                                        onClick={this.actionClick}>
                                    Add
                                </button>
                            </div>
                        </div>,
                        <table className="w-100 table-hover admin-table" key="blog-table">
                            <thead>
                            <tr>
                                <th/>
                                <th>No</th>
                                <th>Title</th>
                                <th>Header Image</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.tableBody()}
                            </tbody>
                        </table>,
                    ]}
            </div>
        );
    };

    actionClick = (event, blog = null) => {
        if (!blog) event.preventDefault();
        editor = null;
        this.setState({
            action: blog ? event : event.target.id,
            selectedBlog: blog,
            isLink: blog ? blog.is_link : true,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            headerImage: null,
        });
    };

    formSubmit = async (event) => {
        let {user, blogs, action, selectedBlog, isLink} = this.state,
            endpoint,
            content = null;
        event.preventDefault();
        if (editor) {
            await editor.save().then((outputData) => {
                content = outputData;
            }).catch((error) => {
                console.log('Saving failed: ', error)
            });
        }
        if (isLink) {
            content = {
                link: event.target.link.value,
                additional_info: event.target.additionalInfo.value
            };
        }
        const data = new FormData();
        if (action === 'Add New Blog') {
            data.append('name', event.target.name.value);
            data.append('is_link', isLink);
            data.append('link', isLink ? event.target.link.value : null);
            data.append('content', JSON.stringify(content));
            if (this.headerImageInput.current.files.length > 0) {
                data.append('header_image', this.headerImageInput.current.files[0]);
            }
            endpoint = '/api/blogs';
        } else {
            data.append('name', selectedBlog.name);
            data.append('is_link', selectedBlog.is_link);
            data.append('link', selectedBlog.link);
            data.append('content', JSON.stringify(content));
            if (selectedBlog.headerImage) {
                data.append('header_image', selectedBlog.headerImage);
            }
            endpoint = '/api/blogs/update/' + selectedBlog.id;
        }
        uploadFile(data, endpoint, user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.setState({
                        error: true,
                        errorMessage: response.data.message,
                        success: false,
                        successMessage: null,
                    });
                } else {
                    if (action === 'Add New Blog') {
                        blogs.push(response.data.blog);
                    } else {
                        blogs[blogs.indexOf(selectedBlog)] = response.data.blog;
                    }
                    this.setState({
                        error: false,
                        errorMessage: null,
                        success: true,
                        successMessage: response.data.message,
                        blogs: blogs,
                    });
                }
            } else {
                this.setState({error: true, errorMessage: 'An error occurred!'});
            }
            this.setState({loading: false});
        });
    }

    tableBody = () => {
        let body = [],
            {blogs, deleteBlogs} = this.state;
        if (blogs) {
            blogs.forEach((blog, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                       id={'blogDelete' + blog.id}
                                       checked={deleteBlogs.includes(blog.id)}
                                       onChange={(event) => this.handleDeleteCheckBox(blog.id)}/>
                                <label className="custom-control-label" htmlFor={'blogDelete' + blog.id}/>
                            </div>
                        </td>
                        <td>{index + 1}</td>
                        <td>{blog.name}</td>
                        <td className="image-column">
                            {blog.header_image_url ?
                                <img src={blog.header_image_url}
                                     style={{width: '65px', height: '65px'}}/>
                                : null
                            }
                        </td>
                        <td className="action-column">
                            <span onClick={() => this.actionClick('Edit Blog', blog)}>Edit</span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    handleDeleteCheckBox = async (id) => {
        let {deleteBlogs} = this.state;
        this.setState({deleteBlogs: handleDeleteCheckBox(id, deleteBlogs)});
    };

    handleDeleteAllCheckBox() {
        let {deleteBlogs, blogs} = this.state;
        this.setState({deleteBlogs: handleDeleteAllCheckBox(deleteBlogs, blogs)});
    }

    delete = () => {
        let {user, blogs, deleteBlogs} = this.state;
        deleteBlogs.forEach((blog, index) => {
            deleteRequest('/api/blogs/' + blog, user.api_token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        this.props.alert.error(response.data.message);
                    } else {
                        if (deleteBlogs.length === index + 1) {
                            this.setState({
                                blogs: blogs.filter(function (blog) {
                                    return !deleteBlogs.includes(blog.id);
                                }),
                                deleteBlogs: []
                            });
                            this.props.alert.success("Successfully deleted Blogs");
                        }
                    }
                } else {
                    this.props.alert.error("An error occurred!");
                }
            });
        });
    }

    handleChange(event, key) {
        let {selectedBlog, headerImage, isLink} = this.state;
        if (selectedBlog) {
            if (key === 'headerImage') {
                headerImage = event.current.files[0];
                selectedBlog[key] = headerImage;
            } else if (key === 'is_link') {
                selectedBlog[key] = event;
                editor = null;
                isLink = event;
            } else {
                selectedBlog[key] = event.target.value;
            }
            this.setState({
                selectedBlog: selectedBlog,
                headerImage: headerImage,
                isLink: isLink,
            });
        } else {
            if (key === 'headerImage') {
                headerImage = event.current.files[0];
            } else if (key === 'is_link') {
                editor = null;
                isLink = event;
            }
            this.setState({
                headerImage: headerImage,
                isLink: isLink,
            });
        }
    }
}

export default withAlert()(withRouter(Blogs));
