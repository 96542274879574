import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {withAlert} from "react-alert";
import EditorJS from '@editorjs/editorjs';
import {getEditorJSTools} from "./editor-constants";

import {getUser} from "../../auth/Auth";
import {handleDeleteAllCheckBox, handleDeleteCheckBox} from "./Common";
import {deleteRequest, uploadFile} from "../../routes/Routes";
import {loadServices} from "../../common/Common";

let editor = null;

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            action: "",
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            services: [],
            deleteServices: [],
            selectedService: null,
            headerImage: null,
            serviceImage1: null,
            serviceImage2: null,
            price: null,
            video: null,
            tagline: null,
        };
        this.headerImageInput = React.createRef();
        this.serviceImage1Input = React.createRef();
        this.serviceImage2Input = React.createRef();
    }

    componentDidMount() {
        getUser(this.props.history).then(data => {
            this.setState({user: data});
        }).catch(() => null);
        loadServices().then(data => {
            this.setState({services: data});
        }).catch(() => null);
    }

    render() {
        let {
            user,
            loading,
            action,
            error,
            errorMessage,
            success,
            successMessage,
            services,
            deleteServices,
            selectedService,
            headerImage,
            serviceImage1,
            serviceImage2,
            price,
            tagline,
            video,

        } = this.state;
        if (user && (action === 'Add New Service' || action === 'Edit Service') && !editor) {
            editor = new EditorJS({
                holder: 'editor',
                tools: getEditorJSTools(user, '/api/services/upload/image'),
                placeholder: 'Service content',
                data: selectedService ? JSON.parse(selectedService.content) : null,
            });
        }
        return (
            <div>
                {action === 'Add New Service' || action === 'Edit Service' ?
                    <form onSubmit={this.formSubmit} className="admin-form">
                        <h1 className="heading admin-heading">{action}</h1>
                        <div className="form-row" style={{marginBottom: '2%'}}>
                            <div className="form-group col-md-4">
                                <label htmlFor="name" className="form-label">Service Name</label>
                                {selectedService ?
                                    <input className="form-control" type="text" placeholder='Service Name' id="name"
                                           required
                                           value={selectedService.name}
                                           onChange={(event) => this.handleChange(event, 'name')}/>
                                    :
                                    <input className="form-control" type="text" placeholder='Service Name' id="name"
                                           required/>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="name" className="form-label">Service Tagline</label>
                                {selectedService ?
                                    <input className="form-control" type="text" placeholder='Service tagline' id="tagline"
                                           required
                                           value={selectedService.tagline}
                                           onChange={(event) => this.handleChange(event, 'tagline')}/>
                                    :
                                    <input className="form-control" type="text" placeholder='Service tagline' id="tagline"
                                           required/>
                                }
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="services" className="form-label">Service List</label>
                                {selectedService ?
                                    <input className="form-control" type="text" placeholder='Service List' id="services"
                                           required
                                           value={selectedService.services}
                                           onChange={(event) => this.handleChange(event, 'services')}/>
                                    :
                                    <input className="form-control" type="text" placeholder='Service List' id="services"
                                           required/>
                                }
                                <small id="services" className="form-text text-muted" style={{fontSize: '14px'}}>
                                    Separate using comma "," i.e: Car Wash, Interior Polish, Exterior Polish
                                </small>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="slug" className="form-label">Service Slug</label>
                                {selectedService ?
                                    <input className="form-control" type="text" placeholder='Service Slug' id="slug"
                                           required
                                           value={selectedService.slug}
                                           onChange={(event) => this.handleChange(event, 'slug')}/>
                                    :
                                    <input className="form-control" type="text" placeholder='Service Slug' id="slug"
                                           required/>
                                }
                                <small id="slug" className="form-text text-muted" style={{fontSize: '14px'}}>
                                    Service URL
                                </small>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="price" className="form-label">Service Price</label>
                                {selectedService ?
                                    <input className="form-control" type="text" placeholder='Service price' id="price"
                                           required
                                           value={selectedService.price}
                                           onChange={(event) => this.handleChange(event, 'price')}/>
                                    :
                                    <input className="form-control" type="text" placeholder='Service price' id="price"
                                           required/>
                                }
                                <small id="price" className="form-text text-muted" style={{fontSize: '14px'}}>
                                    Service price
                                </small>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="price" className="form-label">Service Video URL</label>
                                {selectedService ?
                                    <input className="form-control" type="text" placeholder='https://www.youtube.com/embed/your-video' id="video"
                                           required
                                           value={selectedService.video}
                                           onChange={(event) => this.handleChange(event, 'video')}/>
                                    :
                                    <input className="form-control" type="text" placeholder='https://www.youtube.com/embed/your-video' id="video"
                                           required/>
                                }
                                <small id="video" className="form-text text-muted" style={{fontSize: '14px'}}>
                                    Service video link
                                </small>
                            </div>
                        </div>
                        <div className="custom-file" style={{marginBottom: '2%'}}>
                            {headerImage ?
                                <img src={URL.createObjectURL(headerImage)}
                                     style={{marginBottom: '5%', width: 'auto'}}/>
                                : selectedService ?
                                    <img src={selectedService.header_image_url}
                                         style={{marginBottom: '5%', width: 'auto'}}/>
                                    : null
                            }
                            <input type="file" className="custom-file-input" id="serviceHeaderImage"
                                   accept="image/*"
                                   ref={this.headerImageInput}
                                   onChange={(event) => this.handleChange(this.headerImageInput, 'headerImage')}/>
                            <label className="custom-file-label" htmlFor="serviceHeaderImage">
                                <small id="serviceHeaderImage" className="form-text text-muted"
                                       style={{marginBottom: '2%', textAlign: 'center'}}>
                                    Add Service Header Image
                                </small>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"
                                     alt="Add Icon">
                                    <path fill="none" d="M0 0h24v24H0z"/>
                                    <path
                                        d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993v9.349A5.99 5.99 0 0 0 20 13V5H4l.001 14 9.292-9.293a.999.999 0 0 1 1.32-.084l.093.085 3.546 3.55a6.003 6.003 0 0 0-3.91 7.743L2.992 21A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/>
                                </svg>
                                <div className="image-uploader-label">
                                    Drag & drop or select from your files
                                </div>
                            </label>
                        </div>



                        <div className='custom-service-portal'>
                            <div className="form-group col-md-12 d-flex">
                                <div className="custom-file" style={{marginRight: '2%'}}>
                                    {serviceImage1 ?
                                        <img src={URL.createObjectURL(serviceImage1)}
                                            style={{marginBottom: '5%', width: 'auto'}}/>
                                        : selectedService ?
                                            <img src={selectedService.service_image1_url}
                                                style={{marginBottom: '5%', width: 'auto'}}/>
                                            : null
                                    }
                                    <input type="file" className="custom-file-input" id="serviceImage1"
                                        accept="image/*"
                                        ref={this.serviceImage1Input}
                                        onChange={(event) => this.handleChange1(this.serviceImage1Input, 'serviceImage1')}/>
                                    <label className="custom-file-label" htmlFor="serviceImage1">
                                        <small id="serviceImage1" className="form-text text-muted"
                                            style={{marginBottom: '2%', textAlign: 'center'}}>
                                            Add Individual Service Image 1
                                        </small>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"
                                            alt="Add Icon">
                                            <path fill="none" d="M0 0h24v24H0z"/>
                                            <path
                                                d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993v9.349A5.99 5.99 0 0 0 20 13V5H4l.001 14 9.292-9.293a.999.999 0 0 1 1.32-.084l.093.085 3.546 3.55a6.003 6.003 0 0 0-3.91 7.743L2.992 21A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/>
                                        </svg>
                                        <div className="image-uploader-label">
                                            Drag & drop or select from your files
                                        </div>
                                    </label>
                                </div>
                                <div className="custom-file" style={{marginBottom: '2%'}}>
                                    {serviceImage2 ?
                                        <img src={URL.createObjectURL(serviceImage2)}
                                            style={{marginBottom: '5%', width: 'auto'}}/>
                                        : selectedService ?
                                            <img src={selectedService.service_image2_url}
                                                style={{marginBottom: '5%', width: 'auto'}}/>
                                            : null
                                    }
                                    <input type="file" className="custom-file-input" id="serviceImage2"
                                        accept="image/*"
                                        ref={this.serviceImage2Input}
                                        onChange={(event) => this.handleChange2(this.serviceImage2Input, 'serviceImage2')}/>
                                    <label className="custom-file-label" htmlFor="serviceImage2">
                                        <small id="serviceImage2" className="form-text text-muted"
                                            style={{marginBottom: '2%', textAlign: 'center'}}>
                                            Add Individual Service Image 2
                                        </small>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"
                                            alt="Add Icon">
                                            <path fill="none" d="M0 0h24v24H0z"/>
                                            <path
                                                d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993v9.349A5.99 5.99 0 0 0 20 13V5H4l.001 14 9.292-9.293a.999.999 0 0 1 1.32-.084l.093.085 3.546 3.55a6.003 6.003 0 0 0-3.91 7.743L2.992 21A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/>
                                        </svg>
                                        <div className="image-uploader-label">
                                            Drag & drop or select from your files
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>



                        <div className="form-row" style={{marginBottom: '2%'}}>
                            <div className="form-group col-md-6">
                                <label htmlFor="header_paragraph">Header Paragraph</label>
                                {selectedService ?
                                    <textarea className="form-control" style={{height: 200}} placeholder='Description'
                                              id="header_paragraph"
                                              required
                                              value={selectedService.header_paragraph}
                                              onChange={(event) => this.handleChange(event, 'header_paragraph')}/>
                                    :
                                    <textarea className="form-control" style={{height: 200}} placeholder='Description'
                                              id="header_paragraph"
                                              required/>
                                }
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="editor" className="form-label">Service Editor</label>
                                <div className="form-group text-editor">
                                    <div id="editor"/>
                                </div>
                            </div>
                        </div>
                        {success &&
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>}
                        {error &&
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>}
                        <div className="form-row">
                            <button className="btn white-button form-button col mr-3" style={{marginRight: '5%'}} id=""
                                    onClick={this.actionClick}>
                                Cancel
                            </button>
                            <button className="btn red-button form-button col">
                                {loading ?
                                    <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                          aria-hidden="true"/>
                                    : null
                                }
                                {action === 'Add New Service' ? 'Add' : 'Save'}
                            </button>
                        </div>
                    </form>
                    : [
                        <h1 className="heading admin-heading" key="heading">Services</h1>,
                        <div key="service-delete" className="action-container">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'serviceSelectAll'}
                                       checked={services.length === deleteServices.length}
                                       onChange={() => this.handleDeleteAllCheckBox()}/>
                                <label className="custom-control-label" htmlFor={'serviceSelectAll'}>Select All</label>
                            </div>
                            <div className="action-button-container">
                                <button className="btn gold-border-button" onClick={this.delete}
                                        disabled={deleteServices.length === 0}>
                                    Delete
                                </button>
                                <button className="btn gold-button" id="Add New Service"
                                        onClick={this.actionClick}>
                                    Add
                                </button>
                            </div>
                        </div>,
                        <table className="w-100 table-hover admin-table" key="service-table">
                            <thead>
                            <tr>
                                <th/>
                                <th>No</th>
                                <th>Name</th>
                                <th>Services</th>
                                <th>Header Image</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.tableBody()}
                            </tbody>
                        </table>,
                    ]}
            </div>
        );
    };

    actionClick = (event, service = null) => {
        if (!service) event.preventDefault();
        editor = null;
        this.setState({
            action: service ? event : event.target.id,
            selectedService: service,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            headerImage: null,
            serviceImage1: null,
            price: null,
            tagline: null,

        });
    };

    formSubmit = async (event) => {
        let {user, services, action, selectedService} = this.state,
            endpoint,
            content = null;
        event.preventDefault();
        if (editor) {
            await editor.save().then((outputData) => {
                content = outputData;
            }).catch((error) => {
                console.log('Saving failed: ', error)
            });
        }
        const data = new FormData();
        if (action === 'Add New Service') {
            data.append('name', event.target.name.value);
            data.append('services', event.target.services.value);
            data.append('slug', event.target.slug.value);
            data.append('price', event.target.price.value);
            data.append('video', event.target.video.value);
            data.append('tagline', event.target.tagline.value);
            data.append('content', JSON.stringify(content));
            data.append('header_paragraph', event.target.header_paragraph.value);
            if (this.headerImageInput.current.files.length > 0) {
                data.append('header_image', this.headerImageInput.current.files[0]);
            }
            if (this.serviceImage1Input.current.files.length > 0) {
                data.append('service_image1', this.serviceImage1Input.current.files[0]);
            }
            if (this.serviceImage2Input.current.files.length > 0) {
                data.append('service_image2', this.serviceImage2Input.current.files[0]);
            }
            
            endpoint = '/api/services';
        } else {
            data.append('name', selectedService.name);
            data.append('services', selectedService.services);
            data.append('price', event.target.price.value);
            data.append('video', event.target.video.value);
            data.append('tagline', event.target.tagline.value);
            data.append('slug', selectedService.slug);
            data.append('content', JSON.stringify(content));
            data.append('header_paragraph', selectedService.header_paragraph);
            if (selectedService.headerImage) {
                data.append('header_image', selectedService.headerImage);
            }
            if (selectedService.serviceImage1) {
                data.append('service_image1', selectedService.serviceImage1);
            }
            if (selectedService.serviceImage2) {
                data.append('service_image2', selectedService.serviceImage2);
            }
            endpoint = '/api/services/update/' + selectedService.id;
        }
        uploadFile(data, endpoint, user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.setState({
                        error: true,
                        errorMessage: response.data.message,
                        success: false,
                        successMessage: null,
                    });
                } else {
                    if (action === 'Add New Service') {
                        services.push(response.data.service);
                    } else {
                        services[services.indexOf(selectedService)] = response.data.service;
                    }
                    this.setState({
                        error: false,
                        errorMessage: null,
                        success: true,
                        successMessage: response.data.message,
                        services: services,
                    });
                }
            } else {
                this.setState({error: true, errorMessage: 'An error occurred!'});
            }
            this.setState({loading: false});
        });
    }

    tableBody = () => {
        let body = [],
            {services, deleteServices} = this.state;
        if (services) {
            services.forEach((service, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                       id={'serviceDelete' + service.id}
                                       checked={deleteServices.includes(service.id)}
                                       onChange={(event) => this.handleDeleteCheckBox(service.id)}/>
                                <label className="custom-control-label" htmlFor={'serviceDelete' + service.id}/>
                            </div>
                        </td>
                        <td>{index + 1}</td>
                        <td>{service.name}</td>
                        <td>
                            <div style={{
                                width: 200,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}>{service.services}</div>
                        </td>
                        <td className="image-column">
                            {service.header_image_url ?
                                <img src={service.header_image_url}
                                     style={{width: '65px', height: '65px'}}/>
                                : null
                            }
                        </td>
                        <td className="action-column">
                            <span onClick={() => this.actionClick('Edit Service', service)}>Edit</span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    handleDeleteCheckBox = async (id) => {
        let {deleteServices} = this.state;
        this.setState({deleteServices: handleDeleteCheckBox(id, deleteServices)});
    };

    handleDeleteAllCheckBox() {
        let {deleteServices, services} = this.state;
        this.setState({deleteServices: handleDeleteAllCheckBox(deleteServices, services)});
    }

    delete = () => {
        let {user, services, deleteServices} = this.state;
        deleteServices.forEach((service, index) => {
            deleteRequest('/api/services/' + service, user.api_token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        this.props.alert.error(response.data.message);
                    } else {
                        if (deleteServices.length === index + 1) {
                            this.setState({
                                services: services.filter(function (service) {
                                    return !deleteServices.includes(service.id);
                                }),
                                deleteServices: []
                            });
                            this.props.alert.success("Successfully deleted Services");
                        }
                    }
                } else {
                    this.props.alert.error("An error occurred!");
                }
            });
        });
    }

    handleChange(event, key) {
        let {selectedService, headerImage} = this.state;
        if (selectedService) {
            if (key === 'headerImage') {
                headerImage = event.current.files[0];
                selectedService[key] = headerImage;
            } else {
                selectedService[key] = event.target.value;
            }
            this.setState({
                selectedService: selectedService,
                headerImage: headerImage,
            });
        } else {
            if (key === 'headerImage') {
                headerImage = event.current.files[0];
            }
            this.setState({
                headerImage: headerImage,
            });
        }
    }
    handleChange1(event, key) {
        let {selectedService, serviceImage1} = this.state;
        if (selectedService) {
            if (key === 'serviceImage1') {
                serviceImage1 = event.current.files[0];
                selectedService[key] = serviceImage1;
            } else {
                selectedService[key] = event.target.value;
            }
            this.setState({
                selectedService: selectedService,
                serviceImage1: serviceImage1,
            });
        } else {
            if (key === 'serviceImage1') {
                serviceImage1 = event.current.files[0];
            }
            this.setState({
                serviceImage1: serviceImage1,
            });
        }
    }

    handleChange2(event, key) {
        let {selectedService, serviceImage2} = this.state;
        if (selectedService) {
            if (key === 'serviceImage2') {
                serviceImage2 = event.current.files[0];
                selectedService[key] = serviceImage2;
            } else {
                selectedService[key] = event.target.value;
            }
            this.setState({
                selectedService: selectedService,
                serviceImage2: serviceImage2,
            });
        } else {
            if (key === 'serviceImage2') {
                serviceImage2 = event.current.files[0];
            }
            this.setState({
                serviceImage2: serviceImage2,
            });
        }
    }
}

export default withAlert()(withRouter(Services));
