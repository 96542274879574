import React, {Component} from 'react';
import {withAlert} from "react-alert";
import {NavLink, withRouter} from "react-router-dom";
import EditorJS from "@editorjs/editorjs";
import {getEditorJSTools} from "../components/portal/editor-constants";
import {getRequest} from "../routes/Routes";
import GetInTouch from '../components/GetInTouch';
import Partners from '../components/Partners';
import {loadBlogs} from "../common/Common";
import '../assets/css/ContactUs.css';
import '../assets/css/Blog.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
let editor = null;
const truncateText = (text, wordCount) => {
    const words = text.split(' ');
    if (words.length > wordCount) {
        return words.slice(0, wordCount).join(' ') + '...';
    }
    return text;
};
const truncateWords = (text, numWords) => {
    const words = text.split(' ');
    if (words.length > numWords) {
      return words.slice(0, numWords).join(' ') + '...';
    }
    return text;
};
class Blog extends Component {

    blogSliderSettings2 = {
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
        pauseOnHover: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    constructor(props) {
        super(props);
        this.state = {
            blog: null,
            latestBlogs: null,
            blogs: [], // Initialize blogs here
        };
    }

    componentDidMount() {
        this.loadBlog(this.props.match.params.name);
        loadBlogs(3).then(data => {
            this.setState({blogs: data});
            this.state = {
                blogs: [],
            };

        });
    }

    loadBlog = (name) => {
        getRequest('/api/blogs/name/' + name).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.props.alert.error(response.data.message);
                } else {
                    this.setState({blog: response.data.blog, latestBlogs: response.data.blogs});
                    document.getElementById("root").scrollTo(0, 0);
                }
            } else {
                this.props.alert.error("An error occurred!");
            }
        });
    }
    blogsBodyCard = (blogs) => {
        return blogs.slice(0, 3).map((blog, index) => {
            let textContent = "No content available";
            let textCreatedDate = "No content available";
            let additionalInfo = "";

            const parsedContent = JSON.parse(blog.content);

            if (parsedContent) {
                additionalInfo = parsedContent.additional_info;

                if (parsedContent.blocks && parsedContent.blocks.length > 0) {
                    const firstBlock = parsedContent.blocks[0];
                    textContent = firstBlock.data ? firstBlock.data.text : firstBlock.text;
                }
            }

            const createdAtDate = new Date(blog.created_at);
            textCreatedDate = createdAtDate.toDateString();

            const truncatedTextContent = truncateText(textContent, 20);
            const truncatedBlogName = truncateWords(blog.name, 9);

            return (
                <div className="blog-card-detail-container" key={index}>
                    <img
                        src={blog.header_image_url}
                        style={{ overflow: "hidden", width: "100%", height: "30vh", objectFit: "cover" }}
                        alt={`Blog ${index + 1}
                        `}
                    />
                    <div className="blog-card-content">
                        <h1 className="card-heading blog-ttl text-star">{truncatedBlogName}</h1>
                        {blog.is_link ? <p className='blog-para'>{additionalInfo}</p> : <p className='blog-para'>{truncatedTextContent}</p> }
                        <div className='d-flex align-items-start'>
                            <button className="btn white-background-button blg-crd">
                                {blog.is_link ?
                                    <a href={blog.link} target="_blank" rel="noopener noreferrer" className="nav-link" aria-label="read-more">
                                        Read More
                                    </a>
                                    : <NavLink to={"/blog/" + blog.name} className="nav-link" target="_top">
                                        Read More
                                    </NavLink>
                                }
                            </button>
                            {/* <p className="card-date align-items-end text-end col">{textCreatedDate}</p> */}
                        </div>
                    </div>
                </div>
            );
        });
    };

    render() {
        let {blog , blogs} = this.state;
        if (blog) {
            if (!editor) {
                editor = new EditorJS({
                    holder: 'editor',
                    tools: getEditorJSTools(null),
                    readOnly: true,
                    data: JSON.parse(blog.content),
                });
            }
            return (
                <div>
                    <div className="d-flex bd-highlight w-100 blog-detail-container container">
                        <div className="p-2 flex-fill bd-highlight">
                            <h1 className="sin-bg-hd mb-5">{blog.name}</h1>
                            <div id="editor"/>
                        </div>
                        {/* <div className="p-2 flex-fill bd-highlight animate">
                            {this.latestBlogsBody()}
                        </div> */}
                    </div>
                    <div className='bg-smp'>
                <div className="blog-container mt-5 mb-5">
                    <div class="text-center">
                        <h1 className="text-center mb-4 eleccar-heading">
                            <span>Latest Insights</span>
                        </h1>
                        <p className="eleccar-paragraph mb-4 text-center">
                            Insights and advice from the experts, from Driveline to you.
                        </p>
                    </div>
                    <div className='Sp-mob-hm-only'>
                        <div className='gap-4 d-flex flex-row services-container mt-5'>
                        <Slider {...this.blogSliderSettings2}>
                            {this.blogsBodyCard(blogs)}
                        </Slider>
                        </div>
                    </div>
                    <div className='Sp-pc-hm-only'>
                        <div className='gap-4 d-flex flex-row services-container mt-5'>
                            {this.blogsBodyCard(blogs)}
                        </div>
                    </div>
                </div>
                </div>
                    <div id="getintouch">
                        <GetInTouch/>
                    </div>
                    <div id="partners">
                        <Partners/>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    latestBlogsBody = () => {
        const truncateText = (text, maxWords) => {
            const words = text.split(' ');
            if (words.length > maxWords) {
                return words.slice(0, maxWords).join(' ') + '...';
            }
            return text;
        };

        let { latestBlogs } = this.state,
            body = [];
        latestBlogs.forEach((blog, index) => {
            let textContent = "No content available";
            let textCreatedDate = "No content available";
            let additionalInfo = "";

            const parsedContent = JSON.parse(blog.content);

            if (parsedContent) {
                additionalInfo = parsedContent.additional_info;

                if (parsedContent.blocks && parsedContent.blocks.length > 0) {
                    const firstBlock = parsedContent.blocks[0];
                    textContent = firstBlock.data ? firstBlock.data.text : firstBlock.text;
                }
            }

            const createdAtDate = new Date(blog.created_at);
            textCreatedDate = createdAtDate.toDateString();

            body.push(
                <div className="latest-blog-container col animate" key={index}>
                    <img
                        src={blog.header_image_url}
                        className="blog-page-img"
                        style={{ overflow: "hidden", width: "100%", height: "40vh", objectFit: "cover" }}
                        alt={blog.name}
                    />
                    <div className="text-capitalize heading blog-card-gray">
                        <h1 className="card-heading text-star">{blog.name}</h1>
                        {blog.is_link ? <p className='blog-para'>{additionalInfo}</p> : <p className='blog-para'>{truncateText(textContent, 27)}</p> }
                        <div className='d-flex spc-btn-blg-im2'>
                            <button className="btn gold-border-button spc-btn-blg-im" style={{ width: '150px' }}>
                                {blog.is_link ? (
                                    <a href={blog.link} target="_blank" className="nav-link">
                                        Read More
                                    </a>
                                ) : (
                                    <NavLink className="nav-link" to={"/blog/" + blog.name} target="_top">
                                        Read More
                                    </NavLink>
                                )}
                            </button>
                            {/* <p className="card-date align-items-end text-end col">{textCreatedDate}</p> */}
                        </div>
                    </div>

                </div>
            );
        });
        return body;
    };
}

export default withAlert()(withRouter(Blog));
