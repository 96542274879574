import React , {Component} from 'react';
import {NavLink} from 'react-router-dom';

import '../assets/css/Home.css';
import '../assets/css/Service.css';
import { loadServices} from "../common/Common";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GetInTouch from "../components/GetInTouch";
import Vdo from '../assets/images/vdo.mp4';
import Loading_icon from '../assets/images/Loading_icon.gif';
import Slider from "react-slick";
import Partners from "../components/Partners";

import {getRequest} from "../routes/Routes";
import { withRouter } from 'react-router-dom';
import EditorJS from "@editorjs/editorjs";
import {getEditorJSTools} from "../components/portal/editor-constants";

let editor = null;
class ServiceInner extends Component {
    serveSliderSettings2 = {
        dots: true,
        arrows: false,
        autoplay: false,
        infinite: true,
        pauseOnHover: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    
    constructor(props) {
        super(props);
        this.state = {
            services: [],
            service: null,
        };
       
    }
    componentDidMount() {
        getRequest('/api/services/' + this.props.match.params.slug).then(response => {
            console.log(response.data);
            console.log(this.props.match.params.slug + "this is about console.log(this.props.match.params.slug); "); 
            if (response.status === 200) {
                if (response.data.error) {
                    this.props.alert.error(response.data.message);
                } else {
                    this.setState({service: response.data.service});
                    document.getElementById("root").scrollTo(0, 0);
                }
            } else {
                this.props.alert.error("An error occurred!");
            }
        });

        loadServices().then(data => {
            this.setState({services: data});
        });
    }
    servicesBodyCard = (services) => {
        const truncateText = (text, maxWords) => {
            const words = text.split(' ');
            if (words.length > maxWords) {
                return words.slice(0, maxWords).join(' ') + '...';
            }
            return text;
        };
    
        let body = [];
        services.slice(0, 3).forEach((service, index) => {
            body.push(
                <div className="card-detail-container" key={index}>
                    <img
                        src={service.header_image_url}
                        style={{ overflow: "hidden", width: "100%", height: "40vh", objectFit: "cover" }}
                        alt={`Service ${index + 1}`}
                    />
                    <div className='card-content'>
                        <h1 className="card-heading">{service.name}</h1>
                        <p className='card-para'>{truncateText(service.header_paragraph, 17)}</p>
                        <button style={{ width: '200px', color: '#292929' }} className="btn service-button gold-border-button">
                            <a style={{ color: '#292929' }} className="nav-link" href="tel:+94712885885">
                                Call Now
                            </a>
                        </button>
                    </div>
                </div>
            );
        });
    
        return body;
    };   
    render() {
        let { services, service } = this.state;
        console.log(service);
        if (service) {
            if (!editor) {
                const parsedContent = JSON.parse(service.content);
                const listDetails = parsedContent.blocks.filter(block => block.type === 'list'); // Assuming list details are stored in a block with type 'list'
                
                if (listDetails.length > 0) {
                    editor = new EditorJS({
                        holder: 'editor',
                        tools: getEditorJSTools(null),
                        readOnly: true,
                        data: { blocks: listDetails }, // Pass only the list details to EditorJS
                    });
                }
            }
            
        return (
            <>
                <div className='header-banner-service-inner'style={{"backgroundImage": "url('" + service.header_image_url + "')"}}  >
                    <h1 className="heading header-heading"  id='contact-us-main-topic'>{service.name}</h1>
                    <div className="container">
                        <p className="container header-paragraph inner-hdr-para">{service.tagline}</p>
                    </div>
                </div>
                <div className='dynamic-service-content'>
                    <div className='left-content'>
                        <span className='tagline'>Driveline Services</span>
                        <h2 className='main-tpc'>{service.name}</h2>

                        <p className='service-details-paragraph'>
                            {service.header_paragraph}
                        </p>

                        <div className='price-box'>
                            <div className='price'>Rs.<span>{service.price}</span></div>
                            <span className='taxp'>T & C Apply</span>
                        </div>

                        <div className='include-list'>
                            <span className='tpc'>Services Include</span>
                            <div id="editor"></div>
                            
                        </div>

                        <button className="btn elbt gold-border-button">
                                <a href="tel:+94712885885" className="nav-link sp-loc-btn" >Call Now</a>
                        </button>
                    </div>
                    {service.service_image1_url && service.service_image2_url && (
                        <div className='right-content'>
                            <div className='image-container-service'>
                                <img src={service.service_image1_url} alt="serviceImage 1"/>
                                <img src={service.service_image2_url} alt="serviceImage 2"/>
                            </div>
                        </div>
                    )}
                </div>
                {service.video && service.video.length > 0 && (
                    <div className="video-container service-inner-video" style={{ width: '100%', overflow: 'hidden' }}>
                        <iframe 
                            width="100%" 
                            height="470px" 
                            src={service.video}
                            title="video player" 
                            frameBorder="0" 
                            allow="autoplay; fullscreen" 
                            allowFullScreen
                        ></iframe>
                    </div>
                )}

                <div className='offr'>
                    <div className=' mt-5 pt-5 offer-container  offer-container-hm'>
                        <div class="text-center">
                            <span className='spc-serv-inner-tp'>See More Services</span>
                        </div>
                        <div className='sp-visible2'>
                        <div className='gap-4 d-flex flex-row services-container mt-5 '>       
                            {this.servicesBodyCard(services)}
                            <div className="card-detail-container blank-card">
                                <h1 className="blank-card-heading text-light">more<br/><span style={{color:'#FFDDA9'}}>Services</span></h1>
                                <div className='text-end special-arr'>
                                    <button className="arrow align-self-end">
                                    <NavLink className="nav-link" to={"/services/"}>
                                        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.86121 12.2511C0.864025 12.2511 0.0556506 13.0595 0.0556505 14.0566C0.0556504 15.0538 0.864025 15.8622 1.86121 15.8622L1.86121 12.2511ZM28.4157 15.3334C29.1208 14.6283 29.1208 13.485 28.4157 12.7799L16.9252 1.28944C16.2201 0.584322 15.0769 0.584322 14.3718 1.28944C13.6667 1.99455 13.6667 3.13776 14.3718 3.84288L24.5855 14.0566L14.3718 24.2704C13.6667 24.9755 13.6667 26.1187 14.3718 26.8238C15.0769 27.529 16.2201 27.529 16.9252 26.8238L28.4157 15.3334ZM1.86121 15.8622L27.139 15.8622L27.139 12.2511L1.86121 12.2511L1.86121 15.8622Z" id="srv" fill="#FFDDA9"/>
                                        </svg>
                                    </NavLink>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sp-visible'>
                    <div className='gap-4 d-flex flex-row services-container mt-5'>   
                        <Slider {...this.serveSliderSettings2}>    
                        {this.servicesBodyCard(services)}
                        <div className="card-detail-container blank-card">
                            <h1 className="blank-card-heading text-light">more<br/><span style={{color:'#FFDDA9'}}>Services</span></h1>
                            <div className='text-end '>
                                <button className="arrow align-self-end">
                                <NavLink className="nav-link" to={"/services/"}>
                                    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.86121 12.2511C0.864025 12.2511 0.0556506 13.0595 0.0556505 14.0566C0.0556504 15.0538 0.864025 15.8622 1.86121 15.8622L1.86121 12.2511ZM28.4157 15.3334C29.1208 14.6283 29.1208 13.485 28.4157 12.7799L16.9252 1.28944C16.2201 0.584322 15.0769 0.584322 14.3718 1.28944C13.6667 1.99455 13.6667 3.13776 14.3718 3.84288L24.5855 14.0566L14.3718 24.2704C13.6667 24.9755 13.6667 26.1187 14.3718 26.8238C15.0769 27.529 16.2201 27.529 16.9252 26.8238L28.4157 15.3334ZM1.86121 15.8622L27.139 15.8622L27.139 12.2511L1.86121 12.2511L1.86121 15.8622Z" id="srv" fill="#FFDDA9"/>
                                    </svg>
                                </NavLink>
                                </button>
                            </div>
                        </div>
                        </Slider>
                    </div>
                </div>
                    </div>
                </div> 
                <div id="getintouch">
                    <GetInTouch/>
                </div>
                <div id="partners">
                    <Partners/>
                </div>
            </>
        )
    } else {
        return (
          
            <div>
                <img src={Loading_icon} className='loading-single-serv'/>
            </div>
        );
    }
    }
}
        
export default withRouter(ServiceInner);
