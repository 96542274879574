import React, {Component} from 'react';
import CountUp from 'react-countup';

import Partners from "../components/Partners";
import {NavLink, withRouter} from 'react-router-dom';
import '../assets/css/AboutUs.css';
import Footer from "./Footer";
import aboutUsImage from '../assets/images/about-us-1.webp';
import Man from '../assets/images/man.svg'
import Awd from '../assets/images/awd.webp';
import Awb from '../assets/images/about-us-img.webp';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class AboutUs extends Component {


    awardsSliderSettings = {
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    constructor(props) {
        super(props);
        this.state = {
            services: [],
            testimonials: [],
            blogs: [],
        };
    }

[{/*awardsBody = (blogs, first) => {
        let body = [];
        blogs.forEach((blog, index) => {

            body.push(
                <div key={index}>
                    <div className='row p-5 g-0 d-flex'>
                        <div className='col-4 awart-img-container bg-black'>
                            <img style={{ width: '400px'}} src={Awd} />
                        </div>
                        <div className='col-8' style={{height: '560px'}}>
                            <div className='gradient'></div>
                            <img src={Awb} style={{height: '560px', objectFit: 'cover' , width: '100%'}}/>
                            <div className='award-block'>
                                <p className='page-heading-main text-left award'>Presidential Environmental Awards</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
        return body;
    }*/}]

    render() {
        return (
            <div>
                <div className="header-container about-us-header">
                    <h1 className="heading header-heading-main" id='about-us-section-main-heading'>
                        <span className="page-heading-main " id='about-us-section-main-heading sp-sml-ab about-final-fs '>The golden standard in</span><br/>auto care
                    </h1>
                    <p className="header-paragraph" id='about-us-header-paragraph-intro'>
                    Choosing to be a part of the Self-drive package ensures car maintenance is done the way you want it.
                    </p>
                    <div className="d-flex bd-highlight container mob-on-sp" style={{width: '65%'}}>
                    <div className="mobile">
                            <div className="wrapper">
                                <span><CountUp start={0} end={parseInt("05", 10)} duration={1}/></span>
                                <span><CountUp end={25} duration={3}/></span>
                                <span><CountUp start={90} end={140} duration={6}/></span>
                            </div>
                        <div className="mobile-slider">
                           <span>Service Stations</span>
                           <span>Years of Experience</span>
                           <span>Trained Staff</span>
                        </div>
                    </div>
                        <div className="p-2 flex-fill bd-highlight animate">
                            <span className="heading header-heading-count">
                            <CountUp start={0} end={parseInt("05", 10)} duration={1}/>
                            </span><br/>
                            Service Stations
                        </div>
                        <div className="p-2 flex-fill bd-highlight animate">
                            <span className="heading header-heading-count">
                                <CountUp end={25} duration={1}/>
                            </span><br/>
                            Years of Experience
                        </div>
                        <div className="p-2 flex-fill bd-highlight animate">
                            <span className="heading header-heading-count">
                                <CountUp end={140} duration={1}/>+
                            </span><br/>
                            Trained Staff
                        </div>
                    </div>
                    <div className='dis-mob-off'>
                        <div className='itm'>
                            <span className='num'><CountUp start={0} end={parseInt("05", 10)} duration={1}/></span>
                            <span className='nme'>Service Stations</span>
                        </div>
                        <div className='itm'>
                            <span className='num'><CountUp end={25} duration={1}/></span>
                            <span className='nme'>Years of Experience</span>
                        </div>
                        <div className='itm'>
                            <span className='num'><CountUp end={140} duration={1}/>+</span>
                            <span className='nme'>Trained Staff</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex w-100 col-2 container about-us-container spc-2-abt">
                    <div className="about-para flex-fill" style={{backgroundColor: '#F2F2F2'}}>
                        <h1 className="why-driveline-heading" id='why-driveline-heading'>WHY DRIVELINE?</h1>
                        <div className="about-us-detail-container">
                            <p id='about-us-detail-paragraph'>
                            High-quality products and the service excellence to match is the Driveline way. Built on gearing your four-wheeled companion forward, it's time you felt the Driveline difference.
                            </p>
                        </div>
                    </div>
                    <div className="flex-fill col-5 about-car-img">
                        <img src={aboutUsImage} alt="About Us" className="about-us-image animate"/>
                    </div>
                </div>
                <div className="d-flex gap-5 bd-highlight w-100 col-2 vm-row container about-SP-flx ">
                    <div className="p-2 col flex-fill bd-highlight sp-3-a">
                        <div className="vm-container">
                            <h1 className="heading page-heading mission">Mission</h1>
                            <p className='mission-para'>
                                Deliver customers with quality products and service excellence while
                                adhering to sustainable business practices.
                            </p>
                        </div>
                    </div>
                    <div className="p-2 col flex-fill bd-highlight sp-3-b">
                        <div className="vm-container">
                            <h1 className="heading page-heading mission">Vision</h1>
                            <p className='mission-para'>
                                Revolutionizing Sri Lanka’s auto care industry with service standards that go beyond.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="d-flex col container founder-container sp-3-b-2" style={{backgroundColor: '#F2F2F2'}}>
                    <div className="about-para flex-fill">
                        <h1 className="why-driveline-heading" id='why-driveline-heading'>Founder’s Story</h1>
                        <div className="about-us-detail-container">
                            <p id='about-us-detail-paragraph'>
                            Driveline was built on the foundation of redefining service excellence in Sri Lanka
                            whilst providing a diverse range of services, such as maintenance, pristine standard
                            washes, detailing and running repairs to all makes and models. From the get-go
                            our mission ahead focused on pushing auto care standards in Sri Lanka. With the
                            auto care industry growing at a fast pace, Driveline brings about a service
                            experience that sets the bar high and makes sure our customers feel the difference.
                            Driveline always aims to provide auto care that ensures smiles and the confidence to
                            drive further.
                            </p>
                        </div>
                        <p className='founder-sig'>
                            Avinash
                        </p>
                    </div>
                    <div className="founder-img-container">
                        <img src={Man} alt="Founder" className="founder-image"/>
                    </div>
                </div>
                <div className='awards-container mt-5 container sp-3-b-2"'>
                    <Slider {...this.awardsSliderSettings}>
                        {this.awardsBody}
                        <div className='row p-5 g-0 d-flex'>
                            <div className='col-4 awart-img-container bg-black'>
                                <img style={{ width: '400px'}} src={Awd} />
                            </div>
                            <div className='col-8'>
                                <div className='award-gradient'></div>
                                <img src={Awb} style={{height: '560px', objectFit: 'cover' , width: '100%'}}/>
                                <div className='award-block'>
                                    <p className='page-heading-main text-left award'>Presidential Environmental Awards</p>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
                <div id="partners">
                    <Partners/>
                </div>
                <div className='meet-crew'>
                    <h1 className="heading header-heading-main sp-hdr-hdr-mn" >
                        Meet the Crew
                    </h1>
                    <p className="header-paragraph sp-hdr-hdr-para" id='about-us-header-paragraph-intro-crew'>
                        Service excellence through years of experience and expertise.
                    </p>
                    <button style={{width: '200px', color: '#292929'}} className="btn crew-button gold-border-button">
                            <NavLink style={{color: '#292929'}} className="nav-link crw-sp-btn" to={"/crew"}>
                                Learn More
                            </NavLink>
                    </button>
                </div>
                <div id="footer">
                    <Footer />
                </div>
            </div>
        )
    }
}

export default AboutUs;
