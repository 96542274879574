import React from 'react';
import '../assets/css/Getintouch.css';
import {NavLink, withRouter} from 'react-router-dom';

import Bnr from "../assets/images/bnr.webp";
import Bnr2 from "../assets/images/bnar2.webp";

function GetInTouch() {
    return ([
        <div className='getintouchcon'>
            <picture>
                <source media="(max-width: 767px)" srcSet={Bnr2} alt=""/>
                <source media="(max-width: 1023px)" srcSet={Bnr} alt=""/>
                <img src={Bnr} alt="background" className='man-bnr'/>
            </picture>
            <div className='get-in-touch-details'>
                <div className=''>
                <h1 className='mb-5 special-h1-tag'>Get in touch</h1>
                <p className='special-p-tag'>
                    A service experience like never before awaits you, insist on the best and feel the difference today.
                </p>
                <button style={{width: '200px', color: '#292929', background: '#DAA676'}} className="btn service-button mt-3 gold-border-button">
                    {/*<NavLink style={{color: '#FFFF'}} className="nav-link" to={"/"}>
                        Call now
                    </NavLink>*/}
                    <a className="nav-link" href="tel:+94 712 885 885">call now</a>
                </button>
                </div>
            </div>
        </div>
    ])
}

export default GetInTouch;