import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {loadBlogs, loadServices, loadTestimonials} from "../common/Common";
import Partners from "../components/Partners";
import GetInTouch from "../components/GetInTouch";
import '../assets/css/Home.css';
import Footer from "./Footer";
import Vdo from '../assets/images/vdo.mp4';
import Elcar from "../assets/images/eleccar.webp";
import ico1 from "../assets/images/valueico1.webp";
import ico2 from "../assets/images/valueico2.webp";
import ico3 from "../assets/images/valueico3.webp";

import cus from "../assets/images/cus.webp"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

const truncateText = (text, wordCount) => {
    const words = text.split(' ');
    if (words.length > wordCount) {
        return words.slice(0, wordCount).join(' ') + '...';
    }
    return text;
};

const truncateWords = (text, numWords) => {
    const words = text.split(' ');
    if (words.length > numWords) {
      return words.slice(0, numWords).join(' ') + '...';
    }
    return text;
};

class Home extends Component {
    testimonialSliderSettings = {
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
        pauseOnHover: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    blogSliderSettings2 = {
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
        pauseOnHover: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    serveSliderSettings2 = {
        dots: true,
        arrows: false,
        autoplay: false,
        infinite: true,
        pauseOnHover: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    constructor(props) {
        super(props);
        this.state = {
            services: [],
            testimonials: [],
            blogs: [],
        };
    }

    componentDidMount() {
        loadServices().then(data => {
            this.setState({services: data});
        });
        loadTestimonials(3).then(data => {
            this.setState({testimonials: data});
        });
        loadBlogs(3).then(data => {
            this.setState({blogs: data});
        });
    }

    blogsBodyCard = (blogs) => {
        return blogs.slice(0, 3).map((blog, index) => {
            let textContent = "No content available";
            let textCreatedDate = "No content available";
            let additionalInfo = "";

            const parsedContent = JSON.parse(blog.content);

            if (parsedContent) {
                additionalInfo = parsedContent.additional_info;

                if (parsedContent.blocks && parsedContent.blocks.length > 0) {
                    const firstBlock = parsedContent.blocks[0];
                    textContent = firstBlock.data ? firstBlock.data.text : firstBlock.text;
                }
            }

            const createdAtDate = new Date(blog.created_at);
            textCreatedDate = createdAtDate.toDateString();

            const truncatedTextContent = truncateText(textContent, 20);
            const truncatedBlogName = truncateWords(blog.name, 9);

            return (
                <div className="blog-card-detail-container" key={index}>
                    <img
                        src={blog.header_image_url}
                        style={{ overflow: "hidden", width: "100%", height: "30vh", objectFit: "cover" }}
                        alt={`Blog ${index + 1}
                        `}
                    />
                    <div className="blog-card-content">
                        <h1 className="card-heading blog-ttl text-star">{truncatedBlogName}</h1>
                        {blog.is_link ? <p className='blog-para spc-blg-txt'>{additionalInfo}</p> : <p className='blog-para spc-blg-txt'>{truncatedTextContent}</p> }
                        <div className='d-flex align-items-start'>
                            <button className="btn white-background-button blg-crd">
                                {blog.is_link ?
                                    <a href={blog.link} target="_blank" rel="noopener noreferrer" className="nav-link" aria-label="read-more">
                                        Read More
                                    </a>
                                    : <NavLink to={"/blog/" + blog.name} className="nav-link" target="_top">
                                        Read More
                                    </NavLink>
                                }
                            </button>
                            {/* <p className="card-date align-items-end text-end col">{textCreatedDate}</p> */}
                        </div>
                    </div>
                </div>
            );
        });
    };


    servicesBodyCard = (services) => {
        const truncateText = (text, maxWords) => {
            const words = text.split(' ');
            if (words.length > maxWords) {
                return words.slice(0, maxWords).join(' ') + '...';
            }
            return text;
        };

        let body = [];
        services.slice(0, 3).forEach((service, index) => {
            body.push(
                <div className="card-detail-container" key={index}>
                    <img
                        src={service.header_image_url}
                        style={{ overflow: "hidden", width: "100%", height: "40vh", objectFit: "cover" }}
                        alt={`Service ${index + 1}`}
                    />
                    <div className='card-content'>
                        <h1 className="card-heading">{service.name}</h1>
                        <p className='card-para'>{truncateText(service.header_paragraph, 17)}</p>
                        <button style={{ width: '200px', color: '#292929' }} className="btn service-button gold-border-button">
                            <a style={{ color: '#292929' }} className="nav-link" href="tel:+94712885885">
                               Call  Now
                            </a>
                        </button>
                    </div>
                </div>
            );
        });

        return body;
    };

    testimonialsBody = (testimonials) => {
        let body = [];
        testimonials.forEach((testimonial, index) => {
            body.push(
                <div key={index}>
                    <div className="testimonial d-flex flex-row" style={{marginRight: '20px'}}>
                        <div className="card-top text-center">
                            <img src={cus}
                                alt={testimonial.name}
                                style={{borderRadius: 37, width: 68, height: 68}}/>
                        </div>
                        <div className='ml-4'>
                            <div className="testimonial-message-container">
                                <p className="feedbacks-text text-start">{testimonial.message}</p>
                            </div>
                            <div className="d-flex flex-row">
                                <div>
                                    <h1 className="feedbacks-name">{testimonial.name}</h1>
                                    <h5 className="text-start feedbacks-designation">{testimonial.designation}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
        return body;
    }

    render() {
        let {services, testimonials, blogs} = this.state;
        return (
            <div>
                <div className="header-container home-header">
                    <div className="carousel-detail-container first">
                    <video autoPlay muted loop playsInline className="background-video">
                        <source src={Vdo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                        <div className="animate d-flex flex-column align-items-center">
                            <h1 className="heading header-heading sp-sp-pc-hd" id='home-main-heading'>
                                <span className="home-heading" id='home-main-heading'>A new age in <br className='br-sp-deck'/>Sri Lankan auto care</span>
                            </h1>

                            <h1 className="heading header-heading sp-sp-mob-hd" id='home-main-heading'>
                                <span className="home-heading" id='home-main-heading'>A new age<br className='br-sp-deck'/>in Sri Lankan auto care</span>
                            </h1>
                            <p className="header-paragraph sp-home-bnr-tx" id='home-paragraph-description'>
                            Driving a fresh perception of auto care by setting the bar high for all automotive brands in Sri Lanka.
                            </p>
                            <button className="btn hdr gold-border-button sp-hm-fix-btn">
                                <NavLink className="nav-link" to="/about-us">About Us</NavLink>
                            </button>
                        </div>

                    </div>
                </div>
                <div className="location-button-container container animate">
                    <div className="container container-padding-remove container-boxed">
                        <h2 className="page-heading sp-fix-loc-txt" id='location-container-heading-h2'>Find your closest station</h2>
                        <button className="btn gold-border-button sp-loc-btn-parent">
                            <NavLink className="nav-link sp-loc-btn" to="/contact-us">
                                <svg width="17" height="22" viewBox="0 0 17 22" xmlns="http://www.w3.org/2000/svg" fill='#292929' style={{ marginRight: '20px' }}>
                                    <g id="Frame" clip-path="url(#clip0_869_6890)">
                                        <g id="Group">
                                            <g id="Group 58">
                                            <path id="Vector" d="M16.655 8.05069C16.7247 8.86572 16.4983 9.73856 16.1964 10.594C15.5288 12.5073 14.5593 14.2704 13.4738 15.9698C12.365 17.6981 11.1981 19.3975 10.0255 21.0796C9.14892 22.3397 7.51767 22.2993 6.59464 21.0276C5.17237 19.068 3.80816 17.068 2.58327 14.9756C1.7241 13.4958 0.917179 11.9929 0.388908 10.3628C-0.464453 7.74433 0.127675 5.37439 1.74151 3.23566C3.09412 1.44953 4.92856 0.409068 7.15775 0.0911483C8.92832 -0.163187 10.6118 0.12005 12.1908 0.94664C13.7931 1.77901 15.0063 3.01601 15.8075 4.6345C16.3241 5.67497 16.626 6.77902 16.655 8.05069ZM8.3362 13.4438C11.0588 13.4438 13.2706 11.2299 13.2706 8.50156C13.2706 5.84838 11.0008 3.6056 8.32458 3.61716C5.59615 3.62872 3.4018 5.83682 3.4018 8.57092C3.4018 11.2472 5.62518 13.4438 8.3362 13.4438Z" fill=""/>
                                            </g>
                                        <path id="Vector_2" d="M8.33627 11.5373C6.71663 11.5373 5.32919 10.1615 5.31758 8.54304C5.30597 6.94189 6.69921 5.56038 8.32466 5.54304C9.95011 5.53148 11.3434 6.92454 11.3317 8.56616C11.3317 10.1615 9.9443 11.5373 8.33627 11.5373Z" fill=""/>
                                        </g>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_869_6890">
                                    <rect width="16.6667" height="22" fill=""/>
                                    </clipPath>
                                    </defs>
                                </svg>
                                Locate now
                            </NavLink>
                        </button>
                    </div>
                </div>
                <div className="fuelcar-container container">
                    <div className="container row hybridcar">
                        <div class="col">
                            <img className='elcar' src={Elcar} alt='Fuel Car'/>
                        </div>
                        <div class="col text-end">
                            <h1 className="text-end mb-4 eleccar-heading">
                                <span>Hybrid & Electric<br/>Car Services</span>
                            </h1>
                            <p className="eleccar-paragraph mb-4 text-end">
                                Electrified service excellence at your service. 
                            </p>
                            <button className="btn elbt gold-border-button">
                                <NavLink className="nav-link sp-loc-btn" to="/services">OUR SERVICES</NavLink>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='val'>
                <div className='values-container mt-5 pt-5 container values-container'>
                    <div class="text-center">
                        <h1 className="text-center mb-4 eleccar-heading">
                            <span>Our values</span>
                        </h1>
                        <p className="eleccar-paragraph mb-4 text-center">
                        Driveline provides Hybrid & Electric Cars servicing, diagnostics and repairs.
                        </p>
                    </div>
                    <div className='value-cards d-flex justify-content-center gap-5 mt-5'>
                        <div className="value-card-body col d-flex">
                            <img
                                src={ico1}
                                className="card-img-top mx-auto d-block"
                                alt="Card  01"
                                style={{width: "56px", height: "56px"}}
                            />
                            <div>
                                <h5 className="value-card-title">Wide Range of Services</h5>
                                <p className="value-card-text">
                                    From vehicle washing to repairs, we offer a comprehensive range of auto care services.
                                </p>
                            </div>
                        </div>

                        <div className="value-card-body col d-flex">
                            <img
                                src={ico2}
                                className="card-img-top mx-auto d-block"
                                alt="card 02"
                                style={{width: "56px", height: "56px"}}
                            />
                            <div>
                                <h5 className="value-card-title">Personalized Experience</h5>
                                <p className="value-card-text">
                                    Going above and beyond to meet your auto care needs, personalized service that exceeds expectations.
                                </p>
                            </div>
                        </div>

                        <div className="value-card-body col d-flex">
                            <img
                                src={ico3}
                                className="card-img-top mx-auto d-block"
                                alt="card 03"
                                style={{width: "56px", height: "56px"}}
                            />
                            <div>
                                <h5 className="value-card-title">Unmatched Expertise</h5>
                                <p className="value-card-text">
                                    Our skilled professionals brings years of experience and precision to every aspect of automotive care.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div className='ongoing-promo-new'>
                   <div className='text-container'>
                        <div className='container-boxed-special'>
                            <span className='main-topic-new'>
                                Ongoing Promotions
                            </span>
                            <span className='tagline-new'>
                            An automotive service partner built to bring out the best in your ownership experience. Insist on Driveline and feel the difference in AutoCare.
                            </span>
                            <button href="/promotions"  className=' btn elbt gold-border-button ongoing-promo-btn'>
                                <NavLink  to="/promotions">View Offers</NavLink>
                            </button>
                        </div>
                   </div>
                   <div className='gradient-right'></div>
                </div>
                <div className='offr'>
                <div className=' mt-5 pt-5 offer-container  offer-container-hm'>
                    <div class="text-center">
                        <h1 className="text-center mb-4 eleccar-heading">
                            <span>What we offer</span>
                        </h1>
                        <p className="eleccar-paragraph mb-4 text-center">
                            Top-notch auto care standards that cater to all your needs.
                        </p>
                    </div>
                    <div className='sp-visible2'>
                        <div className='gap-4 d-flex flex-row services-container mt-5 '>
                            {this.servicesBodyCard(services)}
                            <div className="card-detail-container blank-card">
                                <h1 className="blank-card-heading text-light">more<br/><span style={{color:'#FFDDA9'}}>Services</span></h1>
                                <div className='text-end special-arr'>
                                    <button className="arrow align-self-end">
                                    <NavLink className="nav-link" to={"/services/"}>
                                        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.86121 12.2511C0.864025 12.2511 0.0556506 13.0595 0.0556505 14.0566C0.0556504 15.0538 0.864025 15.8622 1.86121 15.8622L1.86121 12.2511ZM28.4157 15.3334C29.1208 14.6283 29.1208 13.485 28.4157 12.7799L16.9252 1.28944C16.2201 0.584322 15.0769 0.584322 14.3718 1.28944C13.6667 1.99455 13.6667 3.13776 14.3718 3.84288L24.5855 14.0566L14.3718 24.2704C13.6667 24.9755 13.6667 26.1187 14.3718 26.8238C15.0769 27.529 16.2201 27.529 16.9252 26.8238L28.4157 15.3334ZM1.86121 15.8622L27.139 15.8622L27.139 12.2511L1.86121 12.2511L1.86121 15.8622Z" id="srv" fill="#FFDDA9"/>
                                        </svg>
                                    </NavLink>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sp-visible'>
                    <div className='gap-4 d-flex flex-row services-container mt-5'>
                        <Slider {...this.serveSliderSettings2}>
                        {this.servicesBodyCard(services)}
                        <div className="card-detail-container blank-card">
                            <h1 className="blank-card-heading text-light">more<br/><span style={{color:'#FFDDA9'}}>Services</span></h1>
                            <div className='text-end '>
                                <button className="arrow align-self-end">
                                <NavLink className="nav-link" to={"/services/"}>
                                    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.86121 12.2511C0.864025 12.2511 0.0556506 13.0595 0.0556505 14.0566C0.0556504 15.0538 0.864025 15.8622 1.86121 15.8622L1.86121 12.2511ZM28.4157 15.3334C29.1208 14.6283 29.1208 13.485 28.4157 12.7799L16.9252 1.28944C16.2201 0.584322 15.0769 0.584322 14.3718 1.28944C13.6667 1.99455 13.6667 3.13776 14.3718 3.84288L24.5855 14.0566L14.3718 24.2704C13.6667 24.9755 13.6667 26.1187 14.3718 26.8238C15.0769 27.529 16.2201 27.529 16.9252 26.8238L28.4157 15.3334ZM1.86121 15.8622L27.139 15.8622L27.139 12.2511L1.86121 12.2511L1.86121 15.8622Z" id="srv" fill="#FFDDA9"/>
                                    </svg>
                                </NavLink>
                                </button>
                            </div>
                        </div>
                        </Slider>
                    </div>
                </div>
                </div>
                </div>

                <div className="testimonial-container mt-5 mb-5 p-5 mtmargin">
                    <div class="text-center mtmargin">
                        <h1 className="text-center mb-4 eleccar-heading">
                            <span className='special-testimo-tag'>Testimonials</span>
                        </h1>
                        <p className="eleccar-paragraph mb-5 text-center">
                            The Driveline experience through our customers' words.
                        </p>
                    </div>
                    <Slider {...this.testimonialSliderSettings}>
                        {this.testimonialsBody(testimonials)}
                    </Slider>
                </div>
                <div id="getintouch">
                    <GetInTouch/>
                </div>
                <div id="partners">
                    <Partners/>
                </div>
                {/* <div className="video-container">
                    <video loop muted autoPlay height={'470px'} width={'100%'}>
                        <source src={Vdo} type="video/mp4" />
                            Your browser does not support the video tag.
                    </video>
                </div> */}
                <div className='bg-smp'>
                <div className="blog-container mt-5 mb-5">
                    <div class="text-center">
                        <h1 className="text-center mb-4 eleccar-heading">
                            <span>Latest Insights</span>
                        </h1>
                        <p className="eleccar-paragraph mb-4 text-center">
                            Insights and advice from the experts, from Driveline to you.
                        </p>
                    </div>
                    <div className='Sp-mob-hm-only'>
                        <div className='gap-4 d-flex flex-row services-container mt-5'>
                        <Slider {...this.blogSliderSettings2}>
                            {this.blogsBodyCard(blogs)}
                        </Slider>
                        </div>
                    </div>
                    <div className='Sp-pc-hm-only'>
                        <div className='gap-4 d-flex flex-row services-container mt-5'>
                            {this.blogsBodyCard(blogs)}
                        </div>
                    </div>
                </div>
                </div>
                <div id="footer">
                    <Footer />
                </div>
            </div>
        )
    }
}

export default withRouter(Home);
