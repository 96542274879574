export const handleDeleteCheckBox = (id, deleteArray) => {
    if (deleteArray.includes(id)) {
        deleteArray = deleteArray.filter(function (element) {
            return element !== id
        });
    } else {
        deleteArray.push(id);
    }
    return deleteArray;
};

export const handleDeleteAllCheckBox = (deleteArray, listArray) => {
    if (deleteArray.length === listArray.length) {
        deleteArray = [];
    } else {
        listArray.map(function (element) {
            if (!deleteArray.includes(element.id)) {
                deleteArray.push(element.id);
            }
        });
    }
    return deleteArray;
}