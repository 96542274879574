import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {createUser} from "../auth/Auth";
import {postRequest} from "../routes/Routes";
import '../assets/css/ContactUs.css';
import '../assets/css/Login.css';


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {error: false, errorMessage: null, loading: false};
    }

    render() {
        return (
            <div className="header-container login-header">
                <div className="login-component container">
                    <h1 className="heading">Welcome to Admin Portal</h1>
                    <form onSubmit={this.loginFunction}>
                        <div className="form-group">
                            <label htmlFor="email">Email Address</label>
                            <input type="email" className="form-control" id="email" required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input className="form-control" type="password" id="password" minLength="8" required/>
                        </div>
                        {this.state.error &&
                        <div className="alert alert-danger" role="alert">
                            {this.state.errorMessage}
                        </div>}
                        <button className="btn gold-button login-button">
                            {this.state.loading ?
                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                      aria-hidden="true"/>
                                : null
                            }
                            Login
                        </button>
                    </form>
                </div>
            </div>
        )
    }

    loginFunction = (event) => {
        this.setState({loading: true});
        event.preventDefault();
        let values = {
            email: event.target.email.value,
            password: event.target.password.value,
            endpoint: '/api/login'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.setState({error: true, errorMessage: response.data.message});
                } else {
                    createUser(response.data.user).then(() => {
                        this.props.history.go(
                            this.props.history.push({pathname: "/"})
                        );
                    });
                }
            } else {
                this.setState({error: true, errorMessage: 'An error occurred!'});
            }
            this.setState({loading: false});
        });
    }
}

export default withRouter(Login);
