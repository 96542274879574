import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

import mobileAdminImage from "../assets/images/mobile-admin.png";

class MobileAdmin extends Component {
    componentDidMount() {
        document.getElementsByClassName('fixed-top')[0].style.backgroundColor = "#111111";
    }

    componentWillUnmount() {
        document.getElementsByClassName('fixed-top')[0].style.backgroundColor = "transparent";
    }

    render() {
        return (
            <div className="header-container" style={{textAlign: "center", margin: "40% 0"}}>
                <h1 className="heading header-heading">
                    Oops!
                </h1>
                <img src={mobileAdminImage} alt="Image"/>
                <h2 style={{margin: "10% 0"}}>
                    This page can only be viewed through the desktop.
                </h2>
                <h3>Please visit</h3>
                <NavLink className="nav-link" style={{color: "#515151"}} exact to="/">www.driveline.lk</NavLink>
            </div>
        )
    }
}

export default MobileAdmin;
