import React, {Component} from 'react';
import {withAlert} from "react-alert";
import {withRouter} from "react-router-dom";
import {getRequest} from "../routes/Routes";
import EditorJS from "@editorjs/editorjs";
import {getEditorJSTools} from "../components/portal/editor-constants";

import Partners from "../components/Partners";

import '../assets/css/ContactUs.css';
import '../assets/css/Service.css';

let editor = null;

class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {service: null};
    }

    componentDidMount() {
        getRequest('/api/services/' + this.props.match.params.slug).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.props.alert.error(response.data.message);
                } else {
                    this.setState({service: response.data.service});
                    document.getElementById("root").scrollTo(0, 0);
                }
            } else {
                this.props.alert.error("An error occurred!");
            }
        });
    }

    render() {
        let {service} = this.state;
        if (service) {
            let services = [];
            if (service.services) {
                service.services.split(",").forEach((serviceElement, index) => {
                    if (index === service.services.split(",").length - 1) {
                        services.push(
                            <span key={index} className="services-list">
                                <span className="line">&#8213;</span>&emsp;{serviceElement}
                            </span>
                        )
                    } else {
                        services.push(
                            <span key={index} className="services-list">
                                <span className="line">&#8213;</span>&emsp;{serviceElement}<br/>
                            </span>
                        )
                    }
                });
            }
            if (!editor) {
                editor = new EditorJS({
                    holder: 'editor',
                    tools: getEditorJSTools(null),
                    readOnly: true,
                    data: JSON.parse(service.content),
                });
            }
            return (
                <>
                    <div className="service-container">
                        <div className="header-container contact-us-header"
                             style={{"backgroundImage": "url('" + service.header_image_url + "')"}}>
                            <h1 className="heading header-heading">{service.name}</h1>
                            <div className="container">
                                <p className="container header-paragraph">{service.header_paragraph}</p>
                            </div>
                        </div>
                        <div className="d-flex bd-highlight w-100 container service-detail-container">
                            <div className="p-2 flex-fill bd-highlight">
                                <h1 className="heading page-heading">{service.name}</h1>
                                <div id="editor"/>
                            </div>
                            <div className="p-2 flex-fill bd-highlight grey-background">
                                <h1 className="heading">Services</h1>
                                <br/>
                                {services}
                            </div>
                        </div>
                    </div>
                    <div id="partners">
                        <Partners/>
                    </div>
                    <div className="container service-video-container">
                        <iframe src="https://www.youtube.com/embed/5MOMwkv65LM"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div>
                </>
            );
        } else {
            return null;
        }
    }
}

export default withAlert()(withRouter(Service));
