import {getRequest} from "../routes/Routes";

export const loadBlogs = async (limit = null) => {
    let blogs = [];
    await getRequest(limit ? ('/api/blogs/limit/' + limit) : '/api/blogs').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                blogs = response.data.blogs;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return blogs;
};

export const loadServices = async () => {
    let services = [];
    await getRequest('/api/services').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                services = response.data.services;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return services;
};

export const loadAwards = async () => {
    let awards = [];
    await getRequest('/api/awards').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                awards = response.data.awards;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return awards;
};

export const loadPromotions = async () => {
    let promotions = [];
    await getRequest('/api/promotions').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                promotions = response.data.promotions;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return promotions;
};

export const loadBranches = async () => {
    let branches = [];
    await getRequest('/api/branches').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                branches = response.data.branches;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return branches;
};

export const loadCrewMembers = async () => {
    let crews = [];
    await getRequest('/api/crew').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                crews = response.data.crews;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return crews;
};

export const loadTestimonials = async (limit = null) => {
    let testimonials = [];
    await getRequest(limit ? ('/api/testimonials/limit/' + limit) : '/api/testimonials').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                testimonials = response.data.testimonials;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return testimonials;
};
