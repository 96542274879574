import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {withAlert} from "react-alert";
import {getUser} from "../../auth/Auth";
import {handleDeleteAllCheckBox, handleDeleteCheckBox} from "./Common";
import {deleteRequest, uploadFile} from "../../routes/Routes";
import {loadBranches} from "../../common/Common";

class Branches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            action: "",
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            branches: [],
            deleteBranches: [],
            selectedBranch: null,
        };
    }

    componentDidMount() {
        getUser(this.props.history).then(data => {
            this.setState({user: data});
        }).catch(() => null);
        loadBranches().then(data => {
            this.setState({branches: data});
        }).catch(() => null);
    }

    render() {
        let {
            loading,
            action,
            error,
            errorMessage,
            success,
            successMessage,
            branches,
            deleteBranches,
            selectedBranch,
        } = this.state;
        return (
            <div>
                {action === 'Add New Branch' || action === 'Edit Branch' ?
                    <form onSubmit={this.formSubmit} className="admin-form">
                        <h1 className="heading admin-heading">{action}</h1>
                        <div className="form-group col-md-6">
                            <label htmlFor="name" className="form-label">Branch Name</label>
                            {selectedBranch ?
                                <input className="form-control" type="text" placeholder='Branch Name' id="name"
                                       required
                                       value={selectedBranch.name}
                                       onChange={(event) => this.handleChange(event, 'name')}/>
                                :
                                <input className="form-control" type="text" placeholder='Branch Name' id="name"
                                       required/>
                            }
                        </div>
                        {success &&
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>}
                        {error &&
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>}
                        <div className="form-row">
                        <button className="btn white-button form-button col mr-3" style={{marginRight:'5%'}} id=""
                                onClick={this.actionClick}>
                            Cancel
                        </button>
                        <button className="btn red-button form-button col">
                            {loading ?
                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                      aria-hidden="true"/>
                                : null
                            }
                            {action === 'Add New Branch' ? 'Add' : 'Save'}
                        </button>
                        </div>
                    </form>
                    : [
                        <h1 className="heading admin-heading" key="heading">Branches</h1>,
                        <div key="branch-delete" className="action-container">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'branchSelectAll'}
                                       checked={branches.length === deleteBranches.length}
                                       onChange={() => this.handleDeleteAllCheckBox()}/>
                                <label className="custom-control-label" htmlFor={'branchSelectAll'}>Select All</label>
                            </div>
                            <div className="action-button-container">
                                <button className="btn gold-border-button" onClick={this.delete}
                                        disabled={deleteBranches.length === 0}>
                                    Delete
                                </button>
                                <button className="btn gold-button" id="Add New Branch"
                                        onClick={this.actionClick}>
                                    Add
                                </button>
                            </div>
                        </div>,
                        <table className="w-100 table-hover admin-table" key="branch-table">
                            <thead>
                            <tr>
                                <th/>
                                <th>No</th>
                                <th>Name</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.tableBody()}
                            </tbody>
                        </table>,
                    ]}
            </div>
        );
    };

    actionClick = (event, branch = null) => {
        if (!branch) event.preventDefault();
        this.setState({
            action: branch ? event : event.target.id,
            selectedBranch: branch,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
        });
    };

    formSubmit = (event) => {
        let {user, branches, action, selectedBranch} = this.state,
            endpoint;
        event.preventDefault();
        const data = new FormData();
        if (action === 'Add New Branch') {
            data.append('name', event.target.name.value);
            endpoint = '/api/branches';
        } else {
            data.append('name', selectedBranch.name);
            endpoint = '/api/branches/update/' + selectedBranch.id;
        }
        uploadFile(data, endpoint, user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.setState({
                        error: true,
                        errorMessage: response.data.message,
                        success: false,
                        successMessage: null,
                    });
                } else {
                    if (action === 'Add New Branch') {
                        branches.push(response.data.branch);
                    } else {
                        branches[branches.indexOf(selectedBranch)] = response.data.branch;
                    }
                    this.setState({
                        error: false,
                        errorMessage: null,
                        success: true,
                        successMessage: response.data.message,
                        branches: branches,
                    });
                }
            } else {
                this.setState({error: true, errorMessage: 'An error occurred!'});
            }
            this.setState({loading: false});
        });
    }

    tableBody = () => {
        let body = [],
            {branches, deleteBranches} = this.state;
        if (branches) {
            branches.forEach((branch, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                       id={'branchDelete' + branch.id}
                                       checked={deleteBranches.includes(branch.id)}
                                       onChange={(event) => this.handleDeleteCheckBox(branch.id)}/>
                                <label className="custom-control-label" htmlFor={'branchDelete' + branch.id}/>
                            </div>
                        </td>
                        <td>{index + 1}</td>
                        <td>{branch.name}</td>
                        <td className="action-column">
                            <span onClick={() => this.actionClick('Edit Branch', branch)}>Edit</span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    handleDeleteCheckBox = async (id) => {
        let {deleteBranches} = this.state;
        this.setState({deleteBranches: handleDeleteCheckBox(id, deleteBranches)});
    };

    handleDeleteAllCheckBox() {
        let {deleteBranches, branches} = this.state;
        this.setState({deleteBranches: handleDeleteAllCheckBox(deleteBranches, branches)});
    }

    delete = () => {
        let {user, branches, deleteBranches} = this.state;
        deleteBranches.forEach((branch, index) => {
            deleteRequest('/api/branches/' + branch, user.api_token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        this.props.alert.error(response.data.message);
                    } else {
                        if (deleteBranches.length === index + 1) {
                            this.setState({
                                branches: branches.filter(function (branch) {
                                    return !deleteBranches.includes(branch.id);
                                }),
                                deleteBranches: []
                            });
                            this.props.alert.success("Successfully deleted Branches");
                        }
                    }
                } else {
                    this.props.alert.error("An error occurred!");
                }
            });
        });
    }

    handleChange(event, key) {
        let {selectedBranch} = this.state;
        selectedBranch[key] = event.target.value;
        this.setState({selectedBranch: selectedBranch,});
    }
}

export default withAlert()(withRouter(Branches));
