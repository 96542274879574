import React, {Component} from 'react';
import {loadBranches} from "../common/Common";
import '../assets/css/ContactUs.css';
import '../assets/css/Crew.css';
import Footer from "./Footer";

class Crew extends Component {
    constructor(props) {
        super(props);
        this.state = {branches: []};
    }

    componentDidMount() {
        loadBranches().then(data => {
            this.setState({branches: data});
        }).catch(() => null);
    }

    render() {
        let {branches} = this.state;
        return (
            <div>
                <div className="header-container crew-header">
                    <h1 className="heading crew-main-heading header-heading" id='our-crew-main-heading'>Our Crew</h1>
                    <div className="container">
                        <p className="container header-paragraph" id='our-crew-intro-paragraph'>
                        The Driveline team is geared with expert craftsmanship and the right tools to ensure you drive ahead confidently.
                        </p>
                    </div>
                </div>
                {this.crewBody(branches)}
                <div id="footer">
                    <Footer />
                </div>
            </div>
        )
    }

    crewBody = (branches) => {
        let body = [];
        branches.forEach((branch, index) => {
            let crewList = [];
            branch.crews.forEach((crew, index) => {
                crewList.push(
                    <div className="col" key={'crew' + index}>
                        {crew.profile_image_url ?
                            <img src={crew.profile_image_url} alt={crew.name} className="crew-image"/>
                            : <div className="image no-image"/>
                        }
                        <div className="text-capitalize crew-data-container">
                            <h3 className="heading crew-name">{crew.name}</h3>
                            <p className="cw-p">{crew.designation}</p>
                        </div>
                    </div>
                );
            });
            let managmentList = [];
            branch.crews.forEach((crew, index) => {
                managmentList.push(
                    <div className="col" key={'crew' + index}>
                        {crew.profile_image_url ?
                            <img src={crew.profile_image_url} alt={crew.name} className="image"/>
                            : <div className="image no-image"/>
                        }
                        <div className="text-capitalize crew-data-container">
                            <h3 className="heading crew-name">{crew.name}</h3>
                            <p className="cw-p">{crew.designation}</p>
                        </div>
                    </div>
                );
            });
            if (index === 0) {
                body.push(
                    <div className="crew-container management-crew" key={index}>
                        <div className="container">
                            <h1 className="page-heading crew-heading">The Management</h1>
                            <div className="row row-cols-5 managment-container justify-content-md-center" style={{marginRight:'12%', marginLeft:'12%'}}>{managmentList}</div>
                        </div>
                    </div>
                );
            } else {
                body.push(
                    <div className="crew-container" style={{backgroundColor: '#FFFBF4'}} key={index}>
                        <div className="container team-leads">
                            <h1 className="page-heading crew-heading">Team Leads - {branch.name}</h1>
                            <div className="row row-cols-5 crew-container justify-content-md-center sp-crv">{crewList}</div>
                        </div>

                    </div>
                );
            }
        });
        return body;
    }
}

export default Crew;
