import React from 'react'
import '../assets/css/Studio.css';
import Rectangle_1 from '../assets/images/Rectangle_1.png';
import Rectangle_2 from '../assets/images/Rectangle_2.png';
import Rectangle_3 from '../assets/images/Rectangle_3.png';
import Rectangle_4 from '../assets/images/Rectangle_4.png';
function Studios() {
    return (
        <div>
            <div className="back-color">
                <div className="container-background-image">
                    <div className="content">
                        <div className="logo">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={212}
                                height={29}
                                viewBox="0 0 212 29"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_27_22)">
                                    <path
                                        d="M42.2973 18.471H49.4776C49.3156 20.142 51.3465 20.7945 53.6753 20.7945C56.0041 20.7945 58.3098 20.3117 58.168 18.9539C58.1188 18.4739 57.9221 17.6517 55.6222 17.3971L53.7129 17.1132C51.413 16.8586 45.0022 16.1797 44.4699 11.0235C43.8682 5.21764 48.9424 1.96063 56.7418 1.96063C64.5412 1.96063 69.2162 5.10352 67.1391 10.7689H60.1815C60.2828 9.86174 59.3252 8.47466 56.2413 8.47466C53.8286 8.47466 52.3677 9.2677 52.4863 10.4002C52.5326 10.8538 52.8276 11.5327 54.5663 11.7873L55.9057 11.9863C59.4236 12.6388 65.8748 12.8934 66.4216 18.1608C67.1448 25.1284 60.6965 27.3378 53.1517 27.3378C45.6068 27.3378 40.6107 25.1577 42.3002 18.474L42.2973 18.471Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M186.19 18.471H193.37C193.208 20.142 195.239 20.7945 197.568 20.7945C199.896 20.7945 202.202 20.3117 202.06 18.9539C202.011 18.4739 201.814 17.6517 199.515 17.3971L197.605 17.1132C195.305 16.8586 188.894 16.1797 188.362 11.0235C187.76 5.21764 192.835 1.96063 200.634 1.96063C208.433 1.96063 213.108 5.10352 211.031 10.7689H204.074C204.175 9.86174 203.217 8.47466 200.134 8.47466C197.721 8.47466 196.26 9.2677 196.379 10.4002C196.425 10.8538 196.72 11.5327 198.459 11.7873L199.798 11.9863C203.316 12.6388 209.767 12.8934 210.314 18.1608C211.037 25.1284 204.589 27.3378 197.044 27.3378C189.499 27.3378 184.503 25.1577 186.192 18.474L186.19 18.471Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M93.8959 9.891H85.732L82.0725 26.5448H73.573L77.2326 9.891H68.8719L70.3763 2.75366H95.4552L93.8959 9.891Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M94.3559 15.6676L97.2228 2.75366H105.835L102.78 16.2909C102.187 18.6993 103.159 20.2268 105.994 20.2268C108.829 20.2268 110.218 18.7549 110.941 15.4393L113.721 2.75073H122.334L119.348 16.4314C117.572 24.4466 113.634 27.3349 105.554 27.3349C96.5777 27.3349 92.6519 23.2848 94.3559 15.6647V15.6676Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M124.182 2.75366H135.32C143.007 2.75366 146.6 6.29453 147.172 11.8165C148.159 21.333 141.378 26.5448 132.008 26.5448H119.047L124.182 2.75366ZM132.751 19.3782C136.454 19.3782 139.087 16.6304 138.711 13.0046C138.462 10.5962 136.68 9.91733 133.735 9.91733H131.154L129.077 19.3782H132.751Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M151.309 2.75366H159.809L154.674 26.5448H146.174L151.309 2.75366Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M158.727 17.4527C157.874 9.23843 164.273 1.96063 173.869 1.96063C180.91 1.96063 185.31 5.70049 185.944 11.8165C186.8 20.0571 180.401 27.3378 170.805 27.3378C163.764 27.3378 159.363 23.598 158.727 17.4527ZM177.615 13.2036C177.346 10.5992 175.335 9.35256 173.261 9.35256C169.081 9.35256 166.821 12.9783 167.088 16.0948C167.357 18.6993 169.338 19.9459 171.415 19.9459C175.596 19.9459 177.855 16.3202 177.618 13.2065L177.615 13.2036Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M11.5771 24.8973L15.9541 4.10565H23.3572L20.2935 18.6613H33.0572C33.6011 17.3561 34.0206 15.9632 34.3301 14.5029C36.0948 6.11019 33.3118 0 21.4478 0H6.60412L0.5 29H15.3408C21.2627 29 25.5674 27.4695 28.6079 24.8944H11.5742L11.5771 24.8973Z"
                                        fill="#DAA676"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_27_22">
                                        <rect
                                            width={211}
                                            height={29}
                                            fill="white"
                                            transform="translate(0.5)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className="title">Exceptional Care for High-End Vehicles</div>
                        <p className="description">
                            Premium auto care products designed to maintain the luxury and perfection of your vehicle.
                        </p>
                        {/* <a href="tel:0000000" className="rsvp-btn">
                            RSVP
                        </a> */}
                    </div>
                    <a href="" className="back-arr">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={60}
                            height={60}
                            viewBox="0 0 24 25"
                            fill="none"
                        >
                            <path
                                d="M10.8284 12.7048L15.7782 17.6545L14.364 19.0687L8 12.7048L14.364 6.34081L15.7782 7.75501L10.8284 12.7048Z"
                                fill="white"
                            />
                        </svg>
                    </a>
                </div>
            </div>
            <div className="card-container">
                <div className="crd-section-ttl">Explore Our Vehicle Categories</div>
                <div className="crd-section-detail">
                    Find the perfect care solution for every type of vehicle—from compact cars to exotic sports models.
                </div>
                <div className="crd-wrapper">
                    <div className="single-card">
                        <div className="crd-img">
                            <img src={Rectangle_1} alt='Rectangle_1' />
                        </div>
                        <div className="img-description">
                            <h3>Compact Cars</h3>
                            <p>
                                Precision care for your nimble city cruisers.
                            </p>
                        </div>
                    </div>
                    <div className="single-card">
                        <div className="crd-img">
                            <img src={Rectangle_2} alt='Rectangle_2' />
                        </div>
                        <div className="img-description">
                            <h3>Saloons</h3>
                            <p>
                                Sophisticated products for elegant sedans.
                            </p>
                        </div>
                    </div>
                    <div className="single-card">
                        <div className="crd-img">
                            <img src={Rectangle_3} alt='Rectangle_3' />
                        </div>
                        <div className="img-description">
                            <h3>SUVs</h3>
                            <p>
                                High-performance care for powerful SUVs.
                            </p>
                        </div>
                    </div>
                    <div className="single-card">
                        <div className="crd-img">
                            <img src={Rectangle_4} alt='Rectangle_4' />
                        </div>
                        <div className="img-description">
                            <h3>Exotics & Sports Cars</h3>
                            <p>
                                Specialized care for your most thrilling rides.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="black-car-section">
                <div className="wrapper-black-car black-car-container">
                    <h3>High-quality products and the service excellence to match</h3>
                    <p>
                        Experience high-quality car care products tailored for the discerning enthusiast. We deliver excellence in both product range and service, ensuring your vehicle receives the attention it deserves.
                    </p>
                </div>
                <img
                    src="https://res.cloudinary.com/dff4rbfkn/image/upload/v1727862041/691703aa0a29a9a80c7743f26a7d53ff_ior7dn.png"
                    className="abs-img"
                />
            </div>
            <div className="map-area">
                <div className="lft-map">
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe
                                src="https://maps.google.com/maps?q=colombo&t=k&z=13&ie=UTF8&iwloc=&output=embed"
                                frameBorder={0}
                                scrolling="no"
                                style={{ width: 660, height: 440 }}
                            />
                            <style
                                dangerouslySetInnerHTML={{
                                    __html:
                                        "\n                        .mapouter {\n                            display: table;\n                        }\n\n                        .gmap_canvas {\n                            overflow: hidden;\n                            position: relative;\n                            height: 440px;\n                            width: 660px;\n                            background: #fff;\n                        }\n                    "
                                }}
                            />
                            <a href="https://www.taxuni.com/w-4-form/">w4 form</a>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html:
                                        "\n                        .gmap_canvas iframe {\n                            position: relative !important;\n                            z-index: 2 !important;\n                        }\n\n                        .gmap_canvas a {\n                            color: #fff !important;\n                            position: absolute !important;\n                            top: 0 !important;\n                            left: 0 !important;\n                            z-index: 0 !important;\n                        }\n                    "
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="rgt-cont">
                    <h3>Get in touch</h3>
                    <div className="flx-number">
                        <h4> Phone Number</h4> <a href="tel:+94712885885">+94 712 885 885</a>
                    </div>
                    <div className="flx-number">
                        <h4> Email Address</h4>{" "}
                        <a href="mailto:info@driveline.lk" style={{ textTransform: 'lowercase' }}>studios@driveline.lk</a>
                    </div>
                    <div className="flx-number flx-number-no">
                        <h4> Location</h4> <a href="/">294 Nawala Rd, Sri Jayawardenepura Kotte</a>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="ftr-wrapper">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={250}
                        height={34}
                        viewBox="0 0 250 34"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_31_130)">
                            <path
                                d="M49.5229 21.6558H58.0304C57.8384 23.6149 60.2446 24.38 63.0039 24.38C65.7632 24.38 68.495 23.8139 68.327 22.2219C68.2688 21.6593 68.0357 20.6952 65.3107 20.3967L63.0484 20.0639C60.3235 19.7654 52.7278 18.9695 52.0971 12.9243C51.3841 6.11739 57.3962 2.29883 66.6372 2.29883C75.8782 2.29883 81.4173 5.98359 78.9562 12.6258H70.7127C70.8327 11.5622 69.6981 9.93596 66.0442 9.93596C63.1856 9.93596 61.4546 10.8657 61.5951 12.1935C61.65 12.7253 61.9996 13.5212 64.0596 13.8197L65.6466 14.053C69.8147 14.8181 77.4583 15.1166 78.1062 21.2922C78.9631 29.4611 71.3228 32.0514 62.3835 32.0514C53.4441 32.0514 47.5246 29.4954 49.5263 21.6593L49.5229 21.6558Z"
                                fill="white"
                            />
                            <path
                                d="M220.011 21.6556H228.519C228.327 23.6146 230.733 24.3797 233.492 24.3797C236.252 24.3797 238.983 23.8136 238.816 22.2217C238.757 21.659 238.524 20.695 235.799 20.3965L233.537 20.0637C230.812 19.7652 223.216 18.9692 222.586 12.924C221.873 6.11715 227.885 2.29858 237.126 2.29858C246.367 2.29858 251.906 5.98335 249.445 12.6255H241.201C241.321 11.562 240.187 9.93572 236.533 9.93572C233.674 9.93572 231.943 10.8655 232.084 12.1932C232.138 12.725 232.488 13.521 234.548 13.8195L236.135 14.0528C240.303 14.8179 247.947 15.1163 248.595 21.2919C249.452 29.4608 241.811 32.0512 232.872 32.0512C223.933 32.0512 218.013 29.4952 220.015 21.659L220.011 21.6556Z"
                                fill="white"
                            />
                            <path
                                d="M110.659 11.5964H100.986L96.6498 31.1216H86.5793L90.9153 11.5964H81.0093L82.7917 3.22852H112.506L110.659 11.5964Z"
                                fill="white"
                            />
                            <path
                                d="M111.204 18.3687L114.6 3.22828H124.805L121.185 19.0995C120.482 21.9231 121.634 23.7141 124.993 23.7141C128.352 23.7141 129.997 21.9883 130.854 18.1011L134.148 3.22485H144.353L140.815 19.2642C138.711 28.6614 134.046 32.0477 124.472 32.0477C113.836 32.0477 109.185 27.2993 111.204 18.3653V18.3687Z"
                                fill="white"
                            />
                            <path
                                d="M146.543 3.22852H159.739C168.847 3.22852 173.104 7.37988 173.782 13.8539C174.951 25.0112 166.917 31.1216 155.815 31.1216H140.459L146.543 3.22852ZM156.696 22.7193C161.083 22.7193 164.202 19.4977 163.757 15.2469C163.462 12.4233 161.35 11.6273 157.861 11.6273H154.804L152.342 22.7193H156.696Z"
                                fill="white"
                            />
                            <path
                                d="M178.684 3.22852H188.755L182.67 31.1216H172.6L178.684 3.22852Z"
                                fill="white"
                            />
                            <path
                                d="M187.473 20.4617C186.461 10.8312 194.043 2.29858 205.413 2.29858C213.756 2.29858 218.969 6.68325 219.72 13.8538C220.735 23.5151 213.153 32.0512 201.783 32.0512C193.44 32.0512 188.227 27.6665 187.473 20.4617ZM209.852 15.48C209.533 12.4265 207.151 10.965 204.693 10.965C199.74 10.965 197.063 15.2158 197.379 18.8697C197.697 21.9232 200.045 23.3848 202.506 23.3848C207.459 23.3848 210.136 19.1339 209.855 15.4834L209.852 15.48Z"
                                fill="white"
                            />
                            <path
                                d="M13.1245 29.1899L18.3106 4.81352H27.082L23.4521 21.8787H38.5749C39.2193 20.3485 39.7163 18.7154 40.0831 17.0034C42.174 7.16367 38.8766 0 24.8197 0H7.23237L0 34H17.5839C24.6003 34 29.7007 32.2057 33.3032 29.1865H13.1211L13.1245 29.1899Z"
                                fill="#DAA676"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_31_130">
                                <rect width={250} height={34} fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div className="ftr-para">
                        Crafting excellence in automotive care. Driven by passion, perfected by design.

                    </div>
                    <div className="ftr-ico">
                        <a href=" https://facebook.com/profile.php?id=61564673351703">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={9}
                                height={15}
                                viewBox="0 0 9 15"
                                fill="none"
                            >
                                <path
                                    d="M5.23067 8.6372V14.8569H2.37358V8.6372H0V6.11527H2.37358V5.1977C2.37358 1.79117 3.79663 0 6.80756 0C7.73062 0 7.96139 0.148349 8.46687 0.269226V2.76368C7.90095 2.66478 7.74161 2.60984 7.15371 2.60984C6.45592 2.60984 6.0823 2.80764 5.74165 3.19774C5.40099 3.58784 5.23067 4.26365 5.23067 5.23067V6.12076H8.46687L7.59875 8.64269H5.23067V8.6372Z"
                                    fill="#FEFBFB"
                                />
                            </svg>
                        </a>
                        <a href="https://www.instagram.com/drivelinestudios.lk/">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={14}
                                height={13}
                                viewBox="0 0 14 13"
                                fill="none"
                            >
                                <path
                                    d="M11.0728 12.7957H2.59537C1.4239 12.7957 0.466858 11.8387 0.466858 10.6672V2.18979C0.466858 1.01832 1.4239 0.0612793 2.59537 0.0612793H11.0728C12.2443 0.0612793 13.2013 1.01832 13.2013 2.18979V10.6672C13.2013 11.8439 12.2495 12.7957 11.0728 12.7957Z"
                                    fill="#FEFBFB"
                                />
                                <path
                                    d="M6.83668 9.69954C5.96331 9.69954 5.14224 9.35961 4.52513 8.7425C3.90802 8.12539 3.56808 7.30432 3.56808 6.43095C3.56808 5.55758 3.90802 4.73651 4.52513 4.1194C5.14224 3.50229 5.96331 3.16235 6.83668 3.16235C7.71005 3.16235 8.53112 3.50229 9.14823 4.1194C9.76534 4.73651 10.1053 5.55758 10.1053 6.43095C10.1053 7.30432 9.76534 8.12539 9.14823 8.7425C8.52589 9.35961 7.71005 9.69954 6.83668 9.69954ZM6.83668 3.85791C5.41942 3.85791 4.26364 5.00846 4.26364 6.43095C4.26364 7.84821 5.41419 9.00399 6.83668 9.00399C8.25394 9.00399 9.40972 7.85344 9.40972 6.43095C9.40449 5.01369 8.25394 3.85791 6.83668 3.85791Z"
                                    fill="#191A1F"
                                />
                                <path
                                    d="M10.7424 3.05522C11.089 3.05522 11.37 2.77424 11.37 2.42764C11.37 2.08103 11.089 1.80005 10.7424 1.80005C10.3958 1.80005 10.1148 2.08103 10.1148 2.42764C10.1148 2.77424 10.3958 3.05522 10.7424 3.05522Z"
                                    fill="#191A1F"
                                />
                            </svg>
                        </a>
                        <a href="https://www.tiktok.com/@driveline.studios">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#ffffff" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" /></svg>
                        </a>

                    </div>
                    <div className="cpy-rgt">2024 @ All rights reserved | Driveline</div>
                </div>
            </div>
        </div>
    )
}

export default Studios
