import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {withAlert} from "react-alert";
import Select from "react-select";
import {getUser} from "../../auth/Auth";
import {handleDeleteAllCheckBox, handleDeleteCheckBox} from "./Common";
import {deleteRequest, uploadFile} from "../../routes/Routes";
import {loadBranches, loadCrewMembers} from "../../common/Common";
import addImage from "../../assets/images/add.png";

class Crew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            action: "",
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            crews: [],
            deleteCrews: [],
            selectedCrew: null,
            profileImage: null,
            branches: [],
        };
        this.profileImageInput = React.createRef();
    }

    componentDidMount() {
        getUser(this.props.history).then(data => {
            this.setState({user: data});
        }).catch(() => null);
        loadCrewMembers().then(data => {
            this.setState({crews: data});
        }).catch(() => null);
        loadBranches().then(data => {
            this.setState({branches: data});
        }).catch(() => null);
    }

    render() {
        let {
            loading,
            action,
            error,
            errorMessage,
            success,
            successMessage,
            crews,
            deleteCrews,
            selectedCrew,
            profileImage,
            branches,
        } = this.state;
        return (
            <div>
                {action === 'Add New Crew Member' || action === 'Edit Crew Member' ?
                    <form onSubmit={this.formSubmit} className="admin-form">
                        <h1 className="heading admin-heading">{action}</h1>
                        <div className="form-row" style={{marginBottom: '2%'}}>
                            <div className="col">
                        <div className="form-group col">
                            <label htmlFor="name" className="form-label">Crew Member Name</label>
                            {selectedCrew ?
                                <input className="form-control" type="text" placeholder='Crew Member Name' id="name"
                                       required
                                       value={selectedCrew.name}
                                       onChange={(event) => this.handleChange(event, 'name')}/>
                                :
                                <input className="form-control" type="text" placeholder='Crew Member Name' id="name"
                                       required/>
                            }
                        </div>
                        
                        <div className="form-group col">
                            <label htmlFor="designation" className="form-label">Designation</label>
                            {selectedCrew ?
                                <input className="form-control" type="text" placeholder='Designation' id="designation"
                                       required
                                       value={selectedCrew.designation}
                                       onChange={(event) => this.handleChange(event, 'designation')}/>
                                :
                                <input className="form-control" type="text" placeholder='Designation' id="designation"
                                       required/>
                            }
                        </div>
                        <div className="form-group col">
                            <label htmlFor="branch" className="form-label">Branch</label>
                            {selectedCrew ?
                                <Select className="basic-single btn w-100 select" placeholder="Branch"
                                        name="branch" id="branch" options={branches}
                                        defaultValue={typeof selectedCrew.branch === "object" ? selectedCrew.branch : branches.find((branch) => {
                                            return branch.id === Number(selectedCrew.branch_id);
                                        })}
                                        getOptionValue={option => option['id']}
                                        getOptionLabel={option => option['name']} required
                                        onChange={(event) => this.handleChange(event, 'branch')}/>
                                : <Select className="basic-single btn w-100 select" placeholder="Branch"
                                          name="branch" id="branch" options={branches}
                                          getOptionValue={option => option['id']}
                                          getOptionLabel={option => option['name']} required/>
                            }
                        </div>
                        </div>
                       
                        <div className="col">
                        {profileImage ?
                            <img src={URL.createObjectURL(profileImage)} style={{marginBottom: '5%', width: 'auto'}}/>
                            : selectedCrew ?
                                <img src={selectedCrew.profile_image_url} style={{marginBottom: '5%', width: 'auto'}}/>
                                : null
                        }
                        <div className="custom-file" style={{marginBottom: '2%'}}>
                            <input type="file" className="custom-file-input" id="profileImage"
                                   accept="image/*"
                                   ref={this.profileImageInput}
                                   onChange={(event) => this.handleChange(this.profileImageInput, 'profileImage')}/>
                            <label className="custom-file-label" htmlFor="profileImage">
                            <small id="profileImage" className="form-text text-muted" style={{marginBottom:'2%', textAlign:'center'}}>
                                Add Profile Image
                            </small>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" alt="Add Icon">
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993v9.349A5.99 5.99 0 0 0 20 13V5H4l.001 14 9.292-9.293a.999.999 0 0 1 1.32-.084l.093.085 3.546 3.55a6.003 6.003 0 0 0-3.91 7.743L2.992 21A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/>
                            </svg>
                                <div className="image-uploader-label">
                                    Drag & drop or select from your files
                                </div>
                            </label>
                            
                        </div>
                        </div>
                        </div>
                        {success &&
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>}
                        {error &&
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>}
                        <div className="form row">
                        <button className="btn white-button form-button col mr-3" id=""
                                onClick={this.actionClick}>
                            Cancel
                        </button>
                        <button className="btn red-button form-button col">
                            {loading ?
                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                      aria-hidden="true"/>
                                : null
                            }
                            {action === 'Add New Crew Member' ? 'Add' : 'Save'}
                        </button>
                        
                        </div>
                    </form>
                    : [
                        <h1 className="heading admin-heading" key="heading">Crew Members</h1>,
                        <div key="crew-delete" className="action-container">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'crewSelectAll'}
                                       checked={crews.length === deleteCrews.length}
                                       onChange={() => this.handleDeleteAllCheckBox()}/>
                                <label className="custom-control-label" htmlFor={'crewSelectAll'}>Select All</label>
                            </div>
                            <div className="action-button-container">
                                <button className="btn gold-border-button" onClick={this.delete}
                                        disabled={deleteCrews.length === 0}>
                                    Delete
                                </button>
                                <button className="btn gold-button" id="Add New Crew Member"
                                        onClick={this.actionClick}>
                                    Add
                                </button>
                            </div>
                        </div>,
                        <table className="w-100 admin-table" key="crew-table">
                            <thead>
                            <tr>
                                <th/>
                                <th>No</th>
                                <th>Name</th>
                                <th>Branch</th>
                                <th>Designation</th>
                                <th>Image</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.tableBody()}
                            </tbody>
                        </table>,
                    ]}
            </div>
        );
    };

    actionClick = (event, crew = null) => {
        if (!crew) event.preventDefault();
        this.setState({
            action: crew ? event : event.target.id,
            selectedCrew: crew,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            profileImage: null,
        });
    };

    formSubmit = (event) => {
        let {user, crews, action, selectedCrew} = this.state,
            endpoint;
        event.preventDefault();
        if (action === 'Add New Crew Member' && event.target.branch.value === '') {
            this.setState({
                error: true,
                errorMessage: "Select a branch!",
                success: false,
                successMessage: null,
                loading: false
            });
            return;
        }
        const data = new FormData();
        if (action === 'Add New Crew Member') {
            data.append('name', event.target.name.value);
            data.append('designation', event.target.designation.value);
            data.append('branch', event.target.branch.value);
            if (this.profileImageInput.current.files.length > 0) {
                data.append('profile_image', this.profileImageInput.current.files[0]);
            }
            endpoint = '/api/crew';
        } else {
            data.append('name', selectedCrew.name);
            data.append('designation', selectedCrew.designation);
            data.append('branch', typeof selectedCrew.branch === "object" ? selectedCrew.branch.id : selectedCrew.branch_id);
            if (selectedCrew.profileImage) {
                data.append('profile_image', selectedCrew.profileImage);
            }
            endpoint = '/api/crew/update/' + selectedCrew.id;
        }
        uploadFile(data, endpoint, user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.setState({
                        error: true,
                        errorMessage: response.data.message,
                        success: false,
                        successMessage: null,
                    });
                } else {
                    if (action === 'Add New Crew Member') {
                        crews.push(response.data.crew);
                    } else {
                        crews[crews.indexOf(selectedCrew)] = response.data.crew;
                    }
                    this.setState({
                        error: false,
                        errorMessage: null,
                        success: true,
                        successMessage: response.data.message,
                        crews: crews,
                    });
                }
            } else {
                this.setState({error: true, errorMessage: 'An error occurred!'});
            }
            this.setState({loading: false});
        });
    }

    tableBody = () => {
        let body = [],
            {crews, deleteCrews, branches} = this.state;
        if (crews) {
            crews.forEach((crew, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                       id={'crewDelete' + crew.id}
                                       checked={deleteCrews.includes(crew.id)}
                                       onChange={(event) => this.handleDeleteCheckBox(crew.id)}/>
                                <label className="custom-control-label" htmlFor={'crewDelete' + crew.id}/>
                            </div>
                        </td>
                        <td>{index + 1}</td>
                        <td>{crew.name}</td>
                        <td>
                            {typeof crew.branch === "object" ? crew.branch.name : branches.find((branch) => {
                                return branch.id === Number(crew.branch_id);
                            }).name}
                        </td>
                        <td>{crew.designation}</td>
                        <td className="image-column">
                            {crew.profile_image_url ?
                                <img src={crew.profile_image_url}
                                     style={{width: '65px', height: '65px'}}/>
                                : null
                            }
                        </td>
                        <td className="action-column">
                            <span onClick={() => this.actionClick('Edit Crew Member', crew)}>Edit</span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    handleDeleteCheckBox = async (id) => {
        let {deleteCrews} = this.state;
        this.setState({deleteCrews: handleDeleteCheckBox(id, deleteCrews)});
    };

    handleDeleteAllCheckBox() {
        let {deleteCrews, crews} = this.state;
        this.setState({deleteCrews: handleDeleteAllCheckBox(deleteCrews, crews)});
    }

    delete = () => {
        let {user, crews, deleteCrews} = this.state;
        deleteCrews.forEach((crew, index) => {
            deleteRequest('/api/crew/' + crew, user.api_token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        this.props.alert.error(response.data.message);
                    } else {
                        if (deleteCrews.length === index + 1) {
                            this.setState({
                                crews: crews.filter(function (crew) {
                                    return !deleteCrews.includes(crew.id);
                                }),
                                deleteCrews: []
                            });
                            this.props.alert.success("Successfully deleted Crew Members");
                        }
                    }
                } else {
                    this.props.alert.error("An error occurred!");
                }
            });
        });
    }

    handleChange(event, key) {
        let {selectedCrew, profileImage} = this.state;
        if (selectedCrew) {
            if (key === 'profileImage') {
                profileImage = event.current.files[0];
                selectedCrew[key] = profileImage;
            } else if (key === 'branch') {
                selectedCrew[key] = event;
            } else {
                selectedCrew[key] = event.target.value;
            }
            this.setState({selectedCrew: selectedCrew, profileImage: profileImage});
        } else {
            this.setState({profileImage: event.current.files[0]});
        }
    }
}

export default withAlert()(withRouter(Crew));
